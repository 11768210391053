import { Box } from "@mui/system";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import InnerPageComponent from "../Utils/InnerPageComponent";
import { useNavigate, useParams } from "react-router-dom";
import EditOfferandPromotionsFields from "./EditOfferandPromotionsFields";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";

export default function EditOffer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.UpdateOffer + "/" + id,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);

        navigate(-1);
        window.addEventListener("popstate", () => {
          setTimeout(() => {
            window.scrollTo(0, 100);
          }, 100);
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <Box>
        <InnerPageComponent title="Edit Offer" />
      </Box>
      <Box mt={10}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              maxWidth="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                width="50%"
                sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <EditOfferandPromotionsFields id={id} />
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}
