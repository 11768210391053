import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import FormLoader from "shared/components/Loader/FormLoader";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function PropertyArea() {
  const [propertyarea, setpropertyarea] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    try {
      const Callforpropertyarea = async () => {
        const response = await Instance.get(BonneApartApis.GetPropertyArea);
        if (response.data.status === "success") {
          setloading(false);
          setpropertyarea(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      };
      Callforpropertyarea();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }, []);

  return (
    <>
      <Box>
        <InnerPageComponent
          title="Property Area"
          button={true}
          buttontext="Add Property Area"
          url="/add-property-area"
        />
      </Box>

      {loading ? (
        <FormLoader />
      ) : (
        <Box mt={5}>
          <Grid container spacing={2}>
            {propertyarea.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                <Box
                  border="1px solid #F5F5F5"
                  borderRadius="8px"
                  padding="10px"
                  textAlign="center"
                  display="inline-flex"
                  height="100px"
                  width={1}
                  justifyContent="center"
                  flexDirection="column"
                  gap={2}
                  alignItems="center"
                  position="relative"
                >
                  <Typography variant="h5" fontWeight="600">
                    {item.location}
                  </Typography>

                  {/* <CustomDropdownMenu
            position="absolute"
            MenuArray={MenuArray}
            ItemId={item.id}
          /> */}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}
