import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AboutprojectDeFields from "./AboutprojectDeFields";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import FormLoader from "shared/components/Loader/FormLoader";

export default function AboutProjectDe({ deData }) {
  const [aboutProject, setAboutProject] = useState({});

  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.SaveAddAboutProjectDe + "/" + deData,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setAboutProject(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const CallforAboutProjectDe = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetSingleAboutProjectDe + "/" + deData
        );

        if (response.data.status === "success") {
          setAboutProject(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        // toast.error(error.response.data.message);
        console.log(error.response.data.message);
      }
    };
    CallforAboutProjectDe();
  }, [deData]);

  console.log(aboutProject);

  return (
    <>
      <Typography variant="h5">About Project German</Typography>
      <Box mt={2}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={8}>
                <AboutprojectDeFields
                  datadyn={aboutProject.aboutproject}
                  aboutprojectEng={aboutProject.aboutproject_En}
                />
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    mt={2}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={methods.formState.isSubmitting}
                    >
                      {methods.formState.isSubmitting ? (
                        <FormLoader />
                      ) : aboutProject.aboutproject ? (
                        "Update About Project"
                      ) : (
                        "Save About Project"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Box>
                  <img
                    src={
                      BonneApartApis.ImagesList +
                      "bnaaboutprojectimages/" +
                      aboutProject.aboutproject?.about_project_image
                    }
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    alt="about"
                  />

                  <Typography variant="h6" mt={2}>
                    About Project Title
                  </Typography>
                  <Typography variant="body1">
                    {aboutProject.aboutproject?.about_project_title}
                  </Typography>
                  <Typography variant="body1">
                    {aboutProject.aboutproject?.about_project_description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}
