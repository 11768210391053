import { Grid } from "@mui/material";
import React from "react";
import MultipleImageUploader from "../Utils/Uploaders/MultipleFileUploader";

export default function BNAinteriorandexteriorInnerFields() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <MultipleImageUploader filename={"bna_inerior_images"} />
        </Grid>
      </Grid>
    </>
  );
}
