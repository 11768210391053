import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import BNAofferandpromotionsDefields from "shared/components/BnaFormContext/BNAGermanForm/BNAofferandpromotionsDefields";
import MuiIconsDynamic from "shared/components/Icons";
import FormLoader from "shared/components/Loader/FormLoader";
import CustomDropdownMenu from "shared/components/Utils/DynDropdownMenu";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function OfferandPromotionsDe({ id, deData }) {
  const methods = useForm();
  const [offerlist, setOfferList] = useState([]);
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.SaveBnaOfferDe + "/" + deData,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setOfferList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-bna-offer-and-promotion-de/",
      type: "edit",
    },

    {
      name: "Delete",
      icon: MuiIconsDynamic.DeleteOutlineOutlinedIcon,
      url: BonneApartApis.DeleteBnaOfferDe,
      type: "delete",
    },
  ];

  useEffect(() => {
    const CallForBNAofferandpromotionsDe = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetBnaOffersDe + "/" + deData
        );
        if (response.data.status === "success") {
          setOfferList(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBNAofferandpromotionsDe();
  }, [deData]);

  // console.log(offerlist);

  return (
    <>
      <Typography variant="h5">Offers & Promotions German</Typography>
      <Box mt={2}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={8}>
                <BNAofferandpromotionsDefields />
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    mt={2}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={methods.formState.isSubmitting}
                    >
                      {methods.formState.isSubmitting ? (
                        <FormLoader />
                      ) : (
                        "Save Offer & Promotions"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="h6">Offer & Promotions Preview</Typography>
                <Grid container spacing={2} pt={2}>
                  {offerlist.map((offer, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="hide-box"
                    >
                      <Box
                        bgcolor="secondary.main"
                        position="relative"
                        sx={{
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <img
                          width={30}
                          src={
                            BonneApartApis.ImagesList +
                            "icons/" +
                            offer.icon_image
                          }
                          alt="offer"
                        />
                        <Typography variant="h6">
                          {offer.offer_promotions_percentage}
                        </Typography>
                        <Typography variant="body2">
                          {offer.offer_promotions_short_title}
                        </Typography>
                        <Typography variant="body2">
                          {offer.offer_promotions_short_description}
                        </Typography>

                        <CustomDropdownMenu
                          position="absolute"
                          MenuArray={MenuArray}
                          ItemId={offer.id}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}
