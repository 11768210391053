import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormLoader from "../Loader/FormLoader";
import UpdateEmailSignatureForm from "./UpdateEmailSignatureForm";
import InnerPageComponent from "../Utils/InnerPageComponent";
import { toast } from "react-hot-toast";
import BonneApartApis from "../Utils/bonneApartApis";
import Instance from "../Utils/axios";
import { useNavigate } from "react-router-dom";

export default function EditEmailSignatureComponent() {
  const { id: signatureID } = useParams();
  const methods = useForm();
  const naviagte = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.UpdateEmailSignature + "/" + signatureID,
        data
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        naviagte("/email-signature");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <InnerPageComponent title="Update Signature" />
      <FormProvider {...methods}>
        <Box mt={3}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <UpdateEmailSignatureForm id={signatureID} />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  {methods.formState.isSubmitting ? (
                    <FormLoader />
                  ) : (
                    <>Update Email Signature</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </FormProvider>
    </>
  );
}
