import Logo from "../images/bonne-apart-logo.webp";
import Avatar from "../images/avatar_default.jpg";
import Character from "../images/character_3.png";
import Emaar from "../images/emaar.png";

const Images = {
  Logo,
  Avatar,
  Character,
  Emaar,
};

export default Images;
