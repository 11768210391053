import {
  Avatar,
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import MuiIconsDynamic from "shared/components/Icons";
import CustomDropdownMenu from "shared/components/Utils/DynDropdownMenu";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function Blogs() {
  const [rows, setrows] = useState([]);

  useEffect(() => {
    const CallForBlogs = async () => {
      try {
        const response = await Instance.get(BonneApartApis.GetBnaBlogs);
        if (response.data.status === "success") {
          setrows(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBlogs();
  }, []);

  const blockProperty = BonneApartApis.BlockProperty;

  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-bna-blog/",
      type: "edit",
    },

    {
      name: "Block",
      icon: MuiIconsDynamic.BlockOutlinedIcon,
      url: blockProperty,
      type: "block",
    },
  ];

  return (
    <>
      <Box mb={3}>
        <InnerPageComponent
          title="Bonne Apart Blogs"
          button={true}
          url="/add-bna-blog"
          buttontext="Add Blog"
        />
      </Box>

      <Box>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.1)" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="Bna Properties Table">
            <TableHead bgcolor="#f4f6f8">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Blog Title</TableCell>
                <TableCell>Blog Image</TableCell>
                <TableCell>Blog Short Description</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    position: "relative",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.blog_title}</TableCell>
                  <TableCell>
                    <Avatar
                      src={
                        BonneApartApis.ImagesList +
                        "bnablogimages/" +
                        row.blog_image
                      }
                    />
                  </TableCell>
                  <TableCell title={row.blog_short_description}>
                    {row.blog_short_description.slice(0, 100)}
                  </TableCell>
                  <TableCell>
                    <Link to={`/add-bna-blog-german/${row.id}`}>
                      <Button variant="text" size="small" color="primary">
                        Add German
                      </Button>
                    </Link>

                    <Link to={`/add-bna-blog-netherland/${row.id}`}>
                      <Button variant="text" size="small" color="primary">
                        Add Netherland
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {row.status === "active" ? (
                      <Chip
                        label={row.status}
                        color="primary"
                        variant="outlined"
                      />
                    ) : (
                      <Chip
                        label={row.status}
                        color="error"
                        variant="outlined"
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <CustomDropdownMenu
                      position="relative"
                      MenuArray={MenuArray}
                      ItemId={row.id}
                      dynStatus={row.status}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
