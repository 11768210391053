import { Box } from "@mui/system";
import React from "react";
import LandingFormControl from "shared/components/Dashboard/LandingFormControl";

export default function AddLandingPage() {
  return (
    <>
      <Box>
        {/* Add Landing Page Form */}
        <LandingFormControl />
      </Box>
    </>
  );
}
