import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";

export default function BNAamenitiesinnerfields() {
  const [amenities, setAmenities] = useState([]);
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const HandleChange = async () => {
    try {
      const response = await Instance.get(BonneApartApis.BNAGetAmenities);
      if (response.data.status === "success") {
        setAmenities(response.data.data);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const options = amenities.map((item) => {
    return { label: item.label, id: item.value };
  });

  const handleSelect = (event, value) => {
    if (value) {
      setValue(
        "amenities",
        value.map((item) => item.id)
      );
    } else {
      setValue("amenities", []);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.amenities ? true : false}
                label="Select Amenities"
                onFocus={HandleChange}
              />
            )}
            onChange={handleSelect}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>
      </Grid>
    </>
  );
}
