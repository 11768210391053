import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import FormLoader from "shared/components/Loader/FormLoader";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function Bedrooms() {
  const [bedrooms, setBedrooms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const CallForBedrooms = async () => {
      setLoading(true);
      try {
        const response = await Instance.get(BonneApartApis.GetBedrooms);
        if (response) {
          setBedrooms(response.data.data);
          setLoading(false);
        } else {
          toast.error("Bedrooms not found");
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    CallForBedrooms();
  }, []);

  return (
    <>
      <InnerPageComponent
        title="Bedrooms List"
        button={true}
        buttontext="Add Bedroom"
        url="/add-bedroom"
      />
      {loading ? (
        <FormLoader />
      ) : (
        <Box mt={5}>
          {bedrooms.length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h5">No Bedrooms Found</Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {bedrooms?.map((bedroom, index) => (
                <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                  <Box
                    border="1px solid #F5F5F5"
                    borderRadius="8px"
                    padding="30px 5px"
                    textAlign="center"
                    position="relative"
                  >
                    <Typography variant="h5">{bedroom.bedroom}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </>
  );
}
