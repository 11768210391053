import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useParams } from "react-router";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import EditProjectFormContext from "shared/components/Utils/EditProjectFormContext";
import AddOfferandPromotions from "shared/components/LandingPage/AddOfferandPromotions";
import AboutProject from "shared/components/LandingPage/AboutProject";
import AddInteriorandExterior from "shared/components/LandingPage/AddInteriorandExterior";
import AmenitiesandAdvanatges from "shared/components/LandingPage/AmenitiesandAdvanatges";
import GoogleMapUrl from "shared/components/LandingPage/GoogleMapUrl";

export default function EditLandingPage() {
  const { id: landingPageId } = useParams();

  return (
    <>
      <InnerPageComponent title="Edit Landing Page" />
      <Box padding="20px 0">
        <Typography variant="h5">General Information</Typography>
        <EditProjectFormContext id={landingPageId} />
        <AddOfferandPromotions id={landingPageId} />
        <AboutProject id={landingPageId} />
        <AddInteriorandExterior id={landingPageId} />
        <AmenitiesandAdvanatges id={landingPageId} />
        <GoogleMapUrl id={landingPageId} />
      </Box>
    </>
  );
}
