import { Box } from "@mui/material";
import React from "react";
import EmailSignatureFormContext from "shared/components/BonneapartUtils/EmailSignatureFormContext";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function AddEmailSignature() {
  return (
    <>
      <InnerPageComponent title="Add Email Signature" />

      <Box mt={3}>
        <EmailSignatureFormContext />
      </Box>
    </>
  );
}
