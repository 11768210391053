import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MuiIconsDynamic from "../Icons";
import BonneApartApis from "../Utils/bonneApartApis";
import CustomDropdownMenu from "../Utils/DynDropdownMenu";
import OfferandPromitionInnerFields from "./OfferandPromitionInnerFields";

export default function OfferandPromotionsFields({ offerlist }) {
  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-offer/",
      type: "edit",
    },

    {
      name: "Delete",
      icon: MuiIconsDynamic.DeleteOutlineOutlinedIcon,
      url: BonneApartApis.DeleteOffer,
      type: "delete",
    },
  ];

  return (
    <>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <OfferandPromitionInnerFields />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography variant="h6">Offer & Promotions Preview</Typography>
            <Grid container spacing={2} pt={2}>
              {offerlist.map((offer, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="hide-box"
                >
                  <Box
                    bgcolor="secondary.main"
                    position="relative"
                    sx={{
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <img
                      width={30}
                      src={
                        BonneApartApis.ImagesList + "icons/" + offer.icon_image
                      }
                      alt="offer"
                    />
                    <Typography variant="h6">
                      {offer.offer_promotions_percentage}
                    </Typography>
                    <Typography variant="body2">
                      {offer.offer_promotions_short_title}
                    </Typography>
                    <Typography variant="body2">
                      {offer.offer_promotions_short_description}
                    </Typography>

                    <CustomDropdownMenu
                      position="absolute"
                      MenuArray={MenuArray}
                      ItemId={offer.id}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
