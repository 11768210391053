import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import FormContext from "./FormContext";
import InnerPageComponent from "../Utils/InnerPageComponent";

export default function LandingFormControl() {
  return (
    <>
      <Box>
        <InnerPageComponent title="Add Landing Page" />
      </Box>
      <Box mt={3}>
        <Typography variant="h5">General Information</Typography>
        <FormContext />
      </Box>
    </>
  );
}
