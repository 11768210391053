import React from "react";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import EmailSignatureComponent from "shared/components/BonneapartUtils/EmailSignatureComponent";
import { Box } from "@mui/material";

export default function EmailSignature() {
  return (
    <>
      <InnerPageComponent
        title="Email Signatures"
        button={true}
        buttontext="Add Email Signature"
        url="/add-email-signature"
      />

      <Box mt={3}>
        <EmailSignatureComponent />
      </Box>
    </>
  );
}
