import { Box, Button, Grid } from "@mui/material";
import React from "react";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import TextForm from "shared/components/Utils/TextForm";
import { FormProvider, useForm } from "react-hook-form";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import FormLoader from "shared/components/Loader/FormLoader";

export default function Addbedroom() {
  const methods = useForm();
  const reset = methods.reset;
  const isSubmitting = methods.formState.isSubmitting;

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(BonneApartApis.SaveBedroom, data);
      if (response.data.status === "success") {
        reset();
        toast.success(response.data.message);
      } else if (response.data.status === "error") {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <InnerPageComponent title="Add Bedroom" />
      <Box mt={10}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              maxWidth="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                width="50%"
                sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box>
                      <TextForm
                        name={"bedroom"}
                        type={"text"}
                        label={"Bedroom"}
                      />
                    </Box>

                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <FormLoader /> : "Submit"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}
