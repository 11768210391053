import React from "react";
import { Box } from "@mui/system";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import LandingPages from "shared/components/Dashboard/LandingPages";

export default function LandingPage() {
  return (
    <>
      <Box>
        <InnerPageComponent
          title="Landing Pages List"
          button={true}
          url="/add-landing-page"
          buttontext="Add Landing Page"
        />
      </Box>

      <Box mt={3}>
        <LandingPages />
      </Box>
    </>
  );
}
