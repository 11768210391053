import { Box, Button, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Images from "shared/assets/images";
import { useForm } from "react-hook-form";
import FormLoader from "shared/components/Loader/FormLoader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { setUser } from "shared/portal/slices/authentication/userSlice";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(BonneApartApis.Login, data);
      if (response.status === 200) {
        dispatch({
          type: setUser.type,
          payload: response.data,
        });
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            maxWidth="100%"
            height="80vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              width="40%"
              sx={{
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              <Box textAlign="center">
                <img src={Images.Logo} width={60} alt="Logo" />
                <Typography mt={3} variant="h2">
                  Sign in to Bonne Apart
                </Typography>
                <Typography variant="body2" mt={1}>
                  Enter your details below.
                </Typography>
              </Box>
              <Box>
                <Box mt={4}>
                  <TextField
                    fullWidth
                    label="Email"
                    {...register("email", { required: true })}
                    variant="outlined"
                    error={errors.email ? true : false}
                  />
                  {errors.email && (
                    <Typography mt={1} color="error" variant="body2">
                      Email is required
                    </Typography>
                  )}
                </Box>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    label="Password"
                    {...register("password", { required: true })}
                    variant="outlined"
                    type="password"
                    error={errors.password ? true : false}
                  />
                  {errors.password && (
                    <Typography mt={1} color="error" variant="body2">
                      Password is required
                    </Typography>
                  )}
                </Box>
                <Box mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting ? true : false}
                    mt={2}
                  >
                    {isSubmitting ? <FormLoader /> : "Sign in"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
}
