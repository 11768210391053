import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link } from "react-router-dom";

const InnerPageComponent = ({ title, url, button, buttontext }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="70px"
      padding="0 20px"
      borderRadius="8px"
      bgcolor="primary.darkbackground"
    >
      <Typography variant="h4" color="primary.dark">
        {title}
      </Typography>
      {button && (
        <Button
          LinkComponent={Link}
          to={url}
          variant="contained"
          color="primary"
          startIcon={<AddOutlinedIcon fontSize="small" />}
        >
          {buttontext}
        </Button>
      )}
    </Box>
  );
};

export default InnerPageComponent;
