import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function GenerateEmailSignature() {
  const { id: signatureID } = useParams();
  const [singleEmailSignature, setSingleEmailSignature] = useState("");

  useEffect(() => {
    const CallforsingleSignature = async () => {
      try {
        await Instance.get(
          BonneApartApis.GetSingleEmailSignature + "/" + signatureID
        ).then((response) => {
          setSingleEmailSignature(response.data.data);
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    CallforsingleSignature();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(singleEmailSignature);
    toast.success("Signature copied to clipboard");
  };

  return (
    <>
      <Box>
        {singleEmailSignature ? (
          <Box>
            <Box mb={2}>
              <Button variant="contained" onClick={handleCopy} color="primary">
                Copy Signature
              </Button>
            </Box>
            {singleEmailSignature}
          </Box>
        ) : (
          <div>Signature not found</div>
        )}
      </Box>
    </>
  );
}
