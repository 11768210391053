import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import AboutProjectSkeleton from "../Skeleton/AboutProjectSkeleton";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import AboutProjectFields from "./AboutProjectFields";

export default function AboutProject({ id }) {
  const [aboutproject, setAboutProject] = useState();
  const [loading, setLoading] = useState(true);

  const methods = useForm();
  const onSubmit = async (data) => {
    if (data.about_project_description === "") {
      data.about_project_description = null;
      toast.error("Please enter about project description");
      return false;
    }
    const formData = new FormData();
    formData.append(
      "about_project_description",
      data.about_project_description
    );
    formData.append("about_project_image", data.about_project_image[0]);
    formData.append("about_project_title", data.about_project_title);

    try {
      const response = await Instance.post(
        BonneApartApis.SaveAboutProject + "/" + id,
        formData
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setAboutProject(response.data.data);
        // methods.reset();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const getAboutProject = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetAboutProject + "/" + id
        );
        if (response.data.status === "success") {
          setAboutProject(response.data.data);
          setLoading(false);
          methods.reset(response.data.data);
        } else {
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getAboutProject();
  }, [id, methods]);

  return (
    <>
      <Box padding="20px 0">
        {loading ? (
          <AboutProjectSkeleton />
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <AboutProjectFields datadyn={aboutproject} />
            </form>
          </FormProvider>
        )}
      </Box>
    </>
  );
}
