import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function ShowIcons() {
  const [icons, setIcons] = useState([]);

  const callforIcons = async () => {
    try {
      const response = await Instance.get(BonneApartApis.GetIcons);
      if (response.data.status === "success") {
        setIcons(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    callforIcons();
  }, []);

  return (
    <>
      <InnerPageComponent
        title="Icons List"
        button={true}
        url="/add-icon"
        buttontext="Add Icon"
      />

      <Box mt={5}>
        <Box>
          <Grid container spacing={2}>
            {icons.length === 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box textAlign="center">
                  <Typography variant="h5">No Icons Found</Typography>
                </Box>
              </Grid>
            )}
            {icons.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                <Box
                  border="1px solid #F5F5F5"
                  borderRadius="8px"
                  padding="20px 5px"
                  textAlign="center"
                  position="relative"
                >
                  <Box>
                    <img
                      src={BonneApartApis.ImagesList + "icons/" + item.icon}
                      width={60}
                      alt="Logo"
                    />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="h5">{item.icon_name}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
