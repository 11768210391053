import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "shared/portal/slices/authentication/userSlice";
import { AppRoutes } from "shared/routes/routes";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { toast } from "react-hot-toast";

export default function AuthenticateUser({ element: MyComponent }) {
  const [component, setComponent] = useState(<></>);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (!token) {
      dispatch(logout());
      navigate(AppRoutes.login.path);
    }

    setComponent(<MyComponent />);

    if (token) {
      try {
        Instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        Instance.post(BonneApartApis.ValidateToken).then(
          (response) => {
            setComponent(<MyComponent />);
          },
          (error) => {
            dispatch(logout());
            toast.error(error.response.data.message);
            navigate(AppRoutes.login.path);
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [navigate, token, dispatch]);

  return component;
}
