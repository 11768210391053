import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import BonneApartApis from "../bonneApartApis";
import MuiIconsDynamic from "shared/components/Icons";

const FloorPlansSlider = ({ handleDelete, savedimages, path }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      {savedimages?.studio?.length > 0 && (
        <Typography variant="h6">Studio</Typography>
      )}
      <Slider {...settings}>
        {savedimages.studio?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.studio?.filter((item) => item.id !== image.id)
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>

      {savedimages?.onebedroom?.length > 0 && (
        <Typography variant="h6">One Bedroom</Typography>
      )}
      <Slider {...settings}>
        {savedimages.onebedroom?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.onebedroom?.filter(
                      (item) => item.id !== image.id
                    )
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>

      {savedimages?.twobedroom?.length > 0 && (
        <Typography variant="h6">Two Bedroom</Typography>
      )}
      <Slider {...settings}>
        {savedimages.twobedroom?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.twobedroom?.filter(
                      (item) => item.id !== image.id
                    )
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>

      {savedimages?.threebedroom?.length > 0 && (
        <Typography variant="h6">Three Bedroom</Typography>
      )}
      <Slider {...settings}>
        {savedimages.threebedroom?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.threebedroom?.filter(
                      (item) => item.id !== image.id
                    )
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>

      {savedimages?.fourbedroom?.length > 0 && (
        <Typography variant="h6">Four Bedroom</Typography>
      )}
      <Slider {...settings}>
        {savedimages.fourbedroom?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.fourbedroom?.filter(
                      (item) => item.id !== image.id
                    )
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>

      {savedimages?.fivebedroom?.length > 0 && (
        <Typography variant="h6">Five Bedroom</Typography>
      )}
      <Slider {...settings}>
        {savedimages.fivebedroom?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.fivebedroom?.filter(
                      (item) => item.id !== image.id
                    )
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>

      {savedimages?.sixbedroom?.length > 0 && (
        <Typography variant="h6">Six Bedroom</Typography>
      )}
      <Slider {...settings}>
        {savedimages.sixbedroom?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.sixbedroom?.filter(
                      (item) => item.id !== image.id
                    )
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>

      {savedimages?.sevenbedroom?.length > 0 && (
        <Typography variant="h6">Seven Bedroom</Typography>
      )}
      <Slider {...settings}>
        {savedimages.sevenbedroom?.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={
                  BonneApartApis.ImagesList + path + image.floor_plans_images
                }
                alt="interior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() =>
                  handleDelete(
                    image.id,
                    savedimages.sevenbedroom?.filter(
                      (item) => item.id !== image.id
                    )
                  )
                }
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>
    </>
  );
};

export default FloorPlansSlider;
