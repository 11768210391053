import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function FormLoader() {
  return (
    <>
      <CircularProgress size="24px" />
    </>
  );
}
