import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import TextForm from "shared/components/Utils/TextForm";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function BNAofferandpromotionsInnerfieldsforGerman({ data }) {
  const { register, errors, setValue } = useFormContext();
  const [icons, setIcons] = useState([]);
  const GetAllIcons = BonneApartApis.GetIconsForSelect;

  useEffect(() => {
    const CallforIconsde = async () => {
      await Instance.get(GetAllIcons).then((response) => {
        setIcons(response.data.data);
      });
    };
    CallforIconsde();
  }, [GetAllIcons]);

  const options = icons.map((icon) => {
    return { label: icon.label, id: icon.value, iconfile: icon.icons };
  });

  useEffect(() => {
    if (data) {
      setValue("offer_promotions_percentage", data.offer_promotions_percentage);
      setValue(
        "offer_promotions_short_title",
        data.offer_promotions_short_title
      );
      setValue(
        "offer_promotions_short_description",
        data.offer_promotions_short_description
      );
    }
  }, [data, setValue]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props}>
                {
                  <img
                    alt="icon"
                    width={18}
                    style={{ marginRight: 10 }}
                    src={BonneApartApis.ImagesList + "icons/" + option.iconfile}
                  />
                }{" "}
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                {...register("offer_promotions_icon", { required: false })}
                label="Search Icon"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextForm
            type={"text"}
            name={"offer_promotions_percentage"}
            label={"Offer & Promotions Percentage (eg: 20%)"}
            autofocus={true}
            isrequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextForm
            type={"text"}
            name={"offer_promotions_short_title"}
            label={"Offer & Promotions Short Title (eg: Down Payment)"}
            isrequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextForm
            type={"text"}
            name={"offer_promotions_short_description"}
            label={"Offer & Promotions Short Description"}
            isrequired={true}
            multiline={true}
          />
        </Grid>
      </Grid>
    </>
  );
}
