import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import MuiIconsDynamic from "shared/components/Icons";
import CustomDropdownMenu from "shared/components/Utils/DynDropdownMenu";

export default function ShowProjectType() {
  const [projectType, setProjectType] = useState([]);

  useEffect(() => {
    try {
      Instance.get(BonneApartApis.ShowProjectType)
        .then((res) => {
          if (res.data.status === "success") {
            setProjectType(res.data.data);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }, []);

  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-project-type/",
      type: "edit",
    },

    {
      name: "Block",
      icon: MuiIconsDynamic.BlockOutlinedIcon,
      url: "/block-developer",
      type: "block",
    },
  ];

  return (
    <>
      <Box>
        <InnerPageComponent
          title="Show Project Type"
          button={true}
          buttontext="Add Project Type"
          url="/save-project-type
        
        "
        />
      </Box>

      <Box mt={5}>
        <Grid container spacing={2}>
          {projectType.length === 0 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box textAlign="center">
                <Typography variant="h5">No Project Types Found</Typography>
              </Box>
            </Grid>
          )}
          {projectType.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <Box
                border="1px solid #F5F5F5"
                borderRadius="8px"
                padding="10px"
                textAlign="center"
                display="inline-flex"
                height="100px"
                width={1}
                justifyContent="center"
                flexDirection="column"
                gap={2}
                alignItems="center"
                position="relative"
              >
                <Typography variant="h5" fontWeight="600">
                  {item.project_type}
                </Typography>

                <CustomDropdownMenu
                  position="absolute"
                  MenuArray={MenuArray}
                  ItemId={item.id}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
