import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import TextForm from "shared/components/Utils/TextForm";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-hot-toast";

export default function AboutprojectDeFields({ datadyn, aboutprojectEng }) {
  const { setValue } = useFormContext();
  const [aboutprojectdetail, setAboutProjectDetail] = useState("");
  const [aboutprojectdetaildyn, setAboutProjectDetailDyn] = useState("");

  const handleOnChange = (editor) => {
    const data = editor.getData();

    if (data === "") {
      return toast.error("About Project Description is required");
    }
    setAboutProjectDetail(data);
  };

  useEffect(() => {
    setValue("about_project_description", aboutprojectdetail);
    setValue("about_project_image", aboutprojectEng?.about_project_image);
    setValue("about_property_title", datadyn?.about_property_title);
    setAboutProjectDetailDyn(datadyn);
    setValue("about_property_title", datadyn?.about_project_title);
  }, [aboutprojectdetail, setValue, datadyn]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6" mb={1}>
            About Project Image{" "}
            <span style={{ color: "red" }}>* (800 x 800px)</span>
          </Typography>
          <TextForm
            type={"text"}
            name={"about_project_image"}
            isrequired={aboutprojectdetaildyn ? false : true}
            isReadOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6" mb={1}>
            <span>&nbsp;</span>
          </Typography>
          <TextForm
            type={"text"}
            name={"about_property_title"}
            label={"About Project Title"}
            isrequired={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" mb={1}>
            About Project Description <span style={{ color: "red" }}>*</span>
          </Typography>
          <CKEditor
            editor={ClassicEditor}
            data={aboutprojectdetaildyn?.about_project_description}
            onChange={(event, editor) => handleOnChange(editor)}
          />
        </Grid>
      </Grid>
    </>
  );
}
