import React, { useEffect, useState } from "react";
import BNAexteriorSlider from "../Utils/Slider/BNAexteriorSlider";
import { Button, Grid, Typography } from "@mui/material";
import BNAexteriorandexteriorInnerFields from "./BNAexteriorandexteriorInnerFields";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import { FormProvider, useForm } from "react-hook-form";
import FormLoader from "../Loader/FormLoader";

export default function BNAexterior({ property }) {
  const [exteriorimages, setExteriorImages] = useState([]);
  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("property_id", property.id);
      for (let i = 0; i < data.bna_exterior_images.length; i++) {
        formData.append("bna_exterior_images[]", data.bna_exterior_images[i]);
      }
      const response = await Instance.post(
        BonneApartApis.BNAsaveexteriorimages + "/" + property.id,
        formData
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setExteriorImages(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    const getExteriorimages = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.BNAgetExteriorimages + "/" + property.id
        );
        if (response.data.status === "success") {
          setExteriorImages(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getExteriorimages();
  }, [property.id]);

  const handleDelete = async (id) => {
    try {
      const response = await Instance.delete(
        BonneApartApis.BNAdeleteExteriorimages + "/" + id
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setExteriorImages(exteriorimages.filter((image) => image.id !== id));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={8} lg={8}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAexteriorandexteriorInnerFields />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} mt={2} textAlign={"center"}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Save Exterior Images"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6">Exterior Images</Typography>

            <BNAexteriorSlider
              savedimages={exteriorimages}
              path={"bnaexteriorimages/"}
              handleDelete={handleDelete}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
