const ImagesList = "https://99-portal.com/bonneapartadmin/public/images/";
// const ImagesList = "http://127.0.0.1:8000/images/";
const Login = "login";
const ValidateToken = "user";
const SaveProject = "save-project";
const UpdateProject = "update-project";
const ProjectsList = "projects";
const GetProject = "get-project";
const BlockProject = "block-project";
const UnblockProject = "unblock-project";
const SaveDeveloper = "save-developer";
const GetDevelopers = "get-developers";
const GetDeveloperforSelect = "get-developer-for-select";
const GetSingleDeveloper = "get-single-developer";
const BlockDeveloper = "block-developer";
const UpdateDeveloper = "update-developer";
const ShowProjectType = "show-project-type";
const ShowProjectTypeForSelect = "show-project-type-for-select";
const SaveProjectType = "save-project-type";
const GetProjectType = "get-project-type";
const UpdateProjectType = "update-project-type";

const UploadQRCode = "upload-qr-code";
// Icons Apis//
const SaveIcon = "save-icon";
const GetIcons = "get-icons";
const GetIconsForSelect = "get-icons-for-select";

// Offers Api's //

const SaveOffer = "save-offer";
const GetOffers = "get-offers";
const GetSingleOffer = "get-single-offer";
const UpdateOffer = "update-offer";
const DeleteOffer = "delete-offer";

// About Project Api's //

const SaveAboutProject = "save-about-project";
const GetAboutProject = "get-about-project";

// Interior & Exterior Api's //
const SaveInteriorImages = "save-interior-images";
const GetInteriorImages = "get-interior-images";
const DeleteInterior = "delete-interior";

const SaveExteriorImages = "save-exterior-images";
const GetExteriorImages = "get-exterior-images";
const DeleteExterior = "delete-exterior";

// Amenities Api's //
const SaveAmenity = "save-amenity";
const GetAmenities = "get-amenities";
const GetAmenityById = "get-amenity-by-id";
const UpdateAmenity = "update-amenity";
const SaveAmenitiesForProject = "save-amenities-for-project";
const GetAmenitiesForProject = "get-amenities-for-project";

// google map api  //

const SaveGoogleMap = "save-google-map";
const GetGoogleMapUrl = "get-google-map-url";

// bedroom api's //
const GetBedrooms = "show-bedrooms";
const SaveBedroom = "save-bedroom";

// save bna property api's //
const SaveBnaProperty = "save-bna-property";
const PropertiesList = "bna-properties";
const BlockProperty = "block-property";
const GetSingleProperty = "get-single-property";
const UpdateBnaProperty = "update-bna-property";

// Save BNA Offer & Promotions //
const SaveBnaOffer = "save-bna-offer-and-promotion";
const GetBnaOffers = "get-bna-offer-and-promotion";
const DeleteBnaOffer = "delete-bna-offer-and-promotion";
const GetSingleOfferandPromotion = "get-single-offer-and-promotion";
const UpdateBnaOffer = "update-bna-offer-and-promotion";

// save bna about property //
const SaveBnaAboutProperty = "save-bna-about-project";
const GetBnaAboutProperty = "get-bna-about-project";
const GetBedroomsList = "get-bedrooms";
const SaveFloorPlanImages = "save-floor-plan-images";
const GetFloorPlanImages = "get-floor-plan-images";
const DeleteFloorPlanImages = "delete-floor-plan-images";

// get bna amenities //
const BNAGetAmenities = "get-bna-amenities";
const BNAsaveAmenities = "save-bna-amenities";
const GetBNAmaenities = "get-bna-amenities";
const BNAamenitiesdelete = "delete-bna-amenities";
const BNAsaveinteriorimages = "save-bna-interior";
const BNAdeleteinteriorimages = "delete-bna-interior";
const BNAgetinteriorimages = "get-bna-interior";
const BNAgetExteriorimages = "get-bna-exterior";
const BNAsaveexteriorimages = "save-bna-exterior";
const BNAdeleteExteriorimages = "delete-bna-exterior";

// save location //
const BNAsavelocation = "save-bna-location";
const BNAgetAddress = "get-bna-address";
const SaveBnaLocationPoints = "save-bna-location-points";
const GetBnaLocationPoints = "get-bna-location-points";
const GetsingleBnaLocationPoints = "get-single-bna-location-points";
const UpdateBnaLocationPoints = "update-bna-location-points";

// Save Property for BNA Germany //

const UpdatePropertyDe = "save-bna-properties-de";

// Add Blogs //

const AddBlogs = "add-blogs";
const GetBnaBlogs = "get-bna-blogs";
const GetsingleBNAblog = "get-single-blog";
const UpdateBnaBlog = "update-bna-blog";

// property area api's //
const GetPropertyArea = "get-property-area";
const AddPropertyArea = "add-property-area";
const ShowPropertyAreaForSelect = "show-property-area-for-select";

// Email Signature //
const GetEmailSignature = "get-email-signature";
const SaveEmailSignature = "save-email-signature";
const DeleteEmailSignature = "delete-email-signature";
const GetSingleEmailSignature = "get-single-email-signature";
const SingleEmailSignature = "single-email-signature";
const UpdateEmailSignature = "update-email-signature";

// Save BNA Property for Germany //
const GetSinglePropertyDe = "get-single-property-de";
const SaveBnaOfferDe = "save-bna-offer-and-promotion-de";
const GetBnaOffersDe = "get-bna-offer-and-promotion-de";
const DeleteBnaOfferDe = "delete-bna-offer-and-promotion-de";
const GetSingleOfferandPromotionsDe = "get-single-offer-and-promotion-de";
const UpdateOfferandPromotionsDe = "update-bna-offer-and-promotion-de";
const GetSingleAboutProjectDe = "get-bna-about-project-de";
const SaveAddAboutProjectDe = "save-bna-about-project-de";
const SaveBNAblogDe = "add-blogs-de";
const SaveBNAblogNl = "add-blogs-nl";

const BonneApartApis = {
  ImagesList,
  Login,
  ValidateToken,
  SaveProject,
  GetProject,
  BlockProject,
  UnblockProject,
  ProjectsList,
  SaveDeveloper,
  GetDevelopers,
  GetDeveloperforSelect,
  GetSingleDeveloper,
  BlockDeveloper,
  UpdateDeveloper,
  SaveIcon,
  GetIcons,
  GetIconsForSelect,
  ShowProjectType,
  ShowProjectTypeForSelect,
  SaveProjectType,
  GetProjectType,
  UpdateProjectType,
  UploadQRCode,
  SaveOffer,
  GetOffers,
  GetSingleOffer,
  UpdateOffer,
  DeleteOffer,
  SaveAboutProject,
  GetAboutProject,
  SaveInteriorImages,
  GetInteriorImages,
  DeleteInterior,
  SaveExteriorImages,
  GetExteriorImages,
  DeleteExterior,
  SaveAmenity,
  GetAmenities,
  GetAmenityById,
  UpdateAmenity,
  SaveAmenitiesForProject,
  GetAmenitiesForProject,
  SaveGoogleMap,
  GetGoogleMapUrl,
  UpdateProject,
  GetBedrooms,
  SaveBedroom,
  SaveBnaProperty,
  PropertiesList,
  BlockProperty,
  GetSingleProperty,
  UpdateBnaProperty,
  SaveBnaOffer,
  GetBnaOffers,
  DeleteBnaOffer,
  GetSingleOfferandPromotion,
  UpdateBnaOffer,
  SaveBnaAboutProperty,
  GetBnaAboutProperty,
  GetBedroomsList,
  SaveFloorPlanImages,
  GetFloorPlanImages,
  DeleteFloorPlanImages,
  BNAGetAmenities,
  BNAsaveAmenities,
  GetBNAmaenities,
  BNAamenitiesdelete,
  BNAsaveinteriorimages,
  BNAdeleteinteriorimages,
  BNAgetinteriorimages,
  BNAgetExteriorimages,
  BNAsaveexteriorimages,
  BNAdeleteExteriorimages,
  BNAsavelocation,
  BNAgetAddress,
  SaveBnaLocationPoints,
  GetBnaLocationPoints,
  GetsingleBnaLocationPoints,
  UpdateBnaLocationPoints,
  AddBlogs,
  GetBnaBlogs,
  GetsingleBNAblog,
  UpdateBnaBlog,
  GetPropertyArea,
  AddPropertyArea,
  ShowPropertyAreaForSelect,
  GetEmailSignature,
  SaveEmailSignature,
  DeleteEmailSignature,
  GetSingleEmailSignature,
  SingleEmailSignature,
  UpdateEmailSignature,
  UpdatePropertyDe,
  GetSinglePropertyDe,
  SaveBnaOfferDe,
  GetBnaOffersDe,
  DeleteBnaOfferDe,
  GetSingleOfferandPromotionsDe,
  UpdateOfferandPromotionsDe,
  GetSingleAboutProjectDe,
  SaveAddAboutProjectDe,
  SaveBNAblogDe,
  SaveBNAblogNl,
};

export default BonneApartApis;
