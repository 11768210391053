import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import FormLoader from "../Loader/FormLoader";
import SlickSlider from "../Utils/Slider/SlickSlider";
import MultipleImageUploader from "../Utils/Uploaders/MultipleFileUploader";

export default function ExteriorFieldDyn({
  savedimages,
  handleExteriorDelete,
}) {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h6" mb={1}>
                    Choose Exterior Images{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* multiple file upload */}
                  <MultipleImageUploader filename={"exterior_images"} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting ? true : false}
                  >
                    {isSubmitting ? <FormLoader /> : <>Save Exterior Images</>}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="h6" mb={1}>
            Exterior Images Preview
          </Typography>
          <Box>
            <SlickSlider
              savedimages={savedimages}
              path="exteriorimages/"
              handleDelete={handleExteriorDelete}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
