import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAaboutpropertyinnerfields from "./BNAaboutpropertyinnerfields";
import { toast } from "react-hot-toast";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import FormLoader from "../Loader/FormLoader";

export default function BNAaboutproperty({ property }) {
  const methods = useForm();
  const [aboutproject, setAboutProject] = useState("");
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("project_id", property.id);
      formData.append("about_project_image", data.about_project_image[0]);
      formData.append("about_property_title", data.about_property_title);
      formData.append(
        "about_project_description",
        data.about_project_description
      );

      const response = await Instance.post(
        BonneApartApis.SaveBnaAboutProperty + "/" + property.id,
        formData
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setAboutProject(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // get about property data //
  useEffect(() => {
    const CallforAboutProperty = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetBnaAboutProperty + "/" + property.id
        );
        if (response.data.status === "success") {
          setAboutProject(response.data.data);
          methods.reset(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallforAboutProperty();
  }, [property.id, methods]);

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">About {property.project_name} </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
              <BNAaboutpropertyinnerfields datadyn={aboutproject} />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  mt={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : aboutproject ? (
                      "Update About Project"
                    ) : (
                      "Save About Project"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box>
                <img
                  src={
                    BonneApartApis.ImagesList +
                    "bnaaboutprojectimages/" +
                    aboutproject?.about_project_image
                  }
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  alt="about"
                />

                <Typography variant="h6" mt={2}>
                  About Project Title
                </Typography>
                <Typography variant="body1">
                  {aboutproject?.about_project_title}
                </Typography>
                <Typography variant="body1">
                  {aboutproject?.about_project_description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
