import { Box } from "@mui/system";
import { React, useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Character from "shared/components/SVG/Character";
import Greetings from "shared/components/Utils/Greetings";
import { useSelector } from "react-redux";

export default function WelcomeComponent() {
  const { user } = useSelector((state) => state.user);
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  useEffect(() => {
    const time = new Date().toLocaleTimeString("en-US");
    const timer = setInterval(() => {
      setTime(time);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [time]);
  return (
    <>
      <Grid item xs={12} md={8} lg={8}>
        <Box
          padding="40px"
          borderRadius="8px"
          bgcolor="primary.darkbackground"
          display="flex"
          alignItems="center"
          columnGap="20px"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" rowGap={1}>
            <Typography variant="h2" color="primary.darktext">
              Welcome Back
            </Typography>
            <Typography variant="body1" color="primary.darktext">
              {user?.name}
            </Typography>
            <Box>
              <Typography variant="h3" color="primary.darktext">
                <Greetings />
                <Typography mt={1} variant="span" ml={1}>
                  {time}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Character />
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
