import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAamenitiesinnerfields from "./BNAamenitiesinnerfields";
import { toast } from "react-hot-toast";
import BonneApartApis from "../Utils/bonneApartApis";
import Instance from "../Utils/axios";
import FormLoader from "../Loader/FormLoader";
import Chip from "@mui/material/Chip";

export default function BNAamenities({ property }) {
  const [dynamenities, setDynamenities] = useState([]);
  const methods = useForm();
  const onSubmit = async (data) => {
    if (!data.amenities) {
      toast.error("Please select amenities");
      return;
    } else if (data.amenities.length === 0) {
      toast.error("Please select amenities");
      return;
    }

    try {
      const response = await Instance.post(
        BonneApartApis.BNAsaveAmenities + "/" + property.id,
        {
          amenities: data.amenities,
        }
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setDynamenities(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const CallForAmenities = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetBNAmaenities + "/" + property.id
        );
        if (response.data.status === "success") {
          setDynamenities(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForAmenities();
  }, [methods, property.id]);

  const handleDelete = (e) => {
    const Callforamenitiesdelete = async () => {
      try {
        const response = await Instance.post(
          BonneApartApis.BNAamenitiesdelete + "/" + property.id,
          {
            amenity_id: e.id,
          }
        );
        if (response.data.status === "success") {
          toast.success(response.data.message);
          setDynamenities(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    Callforamenitiesdelete();
  };

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">Amenities {property.project_name} </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAamenitiesinnerfields />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} mt={2} textAlign={"center"}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Save Amenities"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6">Amenities</Typography>
            <Grid container spacing={2}>
              {dynamenities.map((item, index) => (
                <Grid item xs={12} md={4} lg={4} key={index}>
                  <Chip
                    label={item.amenity.amenity_name}
                    onDelete={(e) => handleDelete(item)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
