import { Box } from "@mui/material";
import React from "react";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import EditBNAofferandpromotionsFormForGerman from "./EditBNAofferandpromotionsFormForGerman";

export default function EditBNAofferandpromotionsForGerman() {
  return (
    <>
      <InnerPageComponent title="Edit BNA offer and promotions For German" />
      <Box mt={2}>
        <EditBNAofferandpromotionsFormForGerman />
      </Box>
    </>
  );
}
