import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import MuiIconsDynamic from "shared/components/Icons";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import CustomDropdownMenu from "shared/components/Utils/DynDropdownMenu";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function Amenities() {
  const [amenities, setAmenities] = useState([]);
  useEffect(() => {
    const callforamenities = async () => {
      try {
        await Instance.get(BonneApartApis.GetAmenities).then((response) => {
          if (response.data.status === "success") {
            setAmenities(response.data.data);
          } else {
            toast.error(response.data.message);
          }
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    callforamenities();
  }, []);

  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-amenity/",
      type: "edit",
    },

    {
      name: "Block",
      icon: MuiIconsDynamic.BlockOutlinedIcon,
      url: "/block-amenity",
      type: "block",
    },
  ];

  return (
    <>
      <InnerPageComponent
        title="Amenities List"
        button={true}
        buttontext="Add Amenities"
        url="/new-amenity"
      />

      <Box mt={5}>
        <Grid container spacing={2}>
          {amenities.length === 0 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box textAlign="center">
                <Typography variant="h5">No Amenities Found</Typography>
              </Box>
            </Grid>
          )}
          {amenities.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <Box
                border="1px solid #F5F5F5"
                borderRadius="8px"
                padding="30px 5px"
                textAlign="center"
                position="relative"
              >
                <Box>
                  <img
                    src={BonneApartApis.ImagesList + "icons/" + item.icon_image}
                    alt={item.amenity_name}
                  />
                </Box>
                <Box mt={2}>
                  <Typography variant="h5">{item.amenity_name}</Typography>
                </Box>
                <CustomDropdownMenu
                  position="absolute"
                  MenuArray={MenuArray}
                  ItemId={item.id}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
