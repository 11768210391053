import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import MuiIconsDynamic from "shared/components/Icons";

const MultipleImageUploader = ({ filename }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [interiorimages, setInteriorImages] = useState([]);

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newImages = [...interiorimages];

    let totalSize = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let isDuplicate = false;

      // Check if file type is supported and file size is less than 500kb
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        if (file.size <= 500 * 1024) {
          // Check if file is a duplicate
          for (let j = 0; j < newImages.length; j++) {
            if (newImages[j].name === file.name) {
              isDuplicate = true;
              toast.error(`File ${file.name} is a duplicate.`);
              break;
            }
          }

          // Add file if it's not a duplicate
          if (!isDuplicate) {
            newImages.push(file);
            totalSize += file.size;
          }
        } else {
          toast.error(`File ${file.name} is too large.`);
        }

        // Stop adding files if total size exceeds 500kb
        if (totalSize > 500 * 1024) {
          toast.error("Total size limit exceeded.");
          break;
        }
      } else {
        toast.error(`File ${file.name} is not supported.`);
      }
    }
    setInteriorImages(newImages);
  };

  useEffect(() => {
    setValue(filename, interiorimages);
  }, [interiorimages, setValue, filename, setInteriorImages, errors]);

  return (
    <>
      <Box
        border={
          interiorimages.length === 0
            ? "1px solid #c4c4c4"
            : "2px solid #00ab55"
        }
        height="auto"
        display="flex"
        padding="20px 0"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        bgcolor={interiorimages.length === 0 ? "#fff" : "#00ab5514"}
        sx={
          interiorimages
            ? {}
            : {
                "&:hover": {
                  border: "1px solid #000",
                },
              }
        }
      >
        <Button
          color={`${errors.filename ? "error" : "primary"}`}
          variant="outlined"
          component="label"
          onChange={(event) => {
            handleImageChange(event);
          }}
        >
          {interiorimages.length === 0 ? (
            <Typography variant="body2" fontWeight={"normal"} color="inherit">
              {`Choose ${filename?.split("_").join(" ")}`} ( 1100 x 650px )
            </Typography>
          ) : (
            <Typography variant="body2" color="inherit">
              {interiorimages.length} {`${filename?.split("_").join(" ")}`} (
              1100 x 650px ) - Add More
            </Typography>
          )}
          {/* Upload Interior Images */}
          <input
            hidden
            accept="image/jpeg, image/png, image/jpg"
            multiple
            type="file"
            {...register(filename, {
              required: interiorimages.length === 0 ? true : false,
            })}
          />
        </Button>
      </Box>
      {interiorimages &&
        interiorimages.map((image, index) => {
          return (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={1}
            >
              <img
                src={URL.createObjectURL(image)}
                alt="interior"
                width="60"
                height="60"
                style={{ borderRadius: 50 }}
              />
              <Typography variant="body2">{image.name}</Typography>

              <Typography variant="body2">
                {
                  // number format //
                  new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(image.size / 1024) + " KB"
                }
              </Typography>

              <Typography variant="body2">
                <IconButton
                  color="error"
                  onClick={() => {
                    const newImages = [...interiorimages];
                    newImages.splice(index, 1);
                    setInteriorImages(newImages);
                    if (newImages.length === 0) {
                      setValue(filename, []);
                      setInteriorImages([]);
                    }
                  }}
                >
                  <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
                </IconButton>
              </Typography>
            </Box>
          );
        })}
    </>
  );
};

export default MultipleImageUploader;
