import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import FormLoader from "../Loader/FormLoader";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";

export default function EditOfferandPromotionsFields({ id }) {
  const [icons, setIcons] = useState([]);
  const [offer, setOffer] = useState();
  const GetAllIcons = BonneApartApis.GetIconsForSelect;
  const GetSingleOffer = BonneApartApis.GetSingleOffer;

  const {
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext();

  useEffect(() => {
    const CallforIcons = async () => {
      await Instance.get(GetAllIcons).then((response) => {
        setIcons(response.data.data);
      });
    };
    CallforIcons();
  }, [GetAllIcons]);

  useEffect(() => {
    const CallforSingleOffer = async () => {
      try {
        await Instance.get(GetSingleOffer + "/" + id).then((response) => {
          setOffer(response.data.data);
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallforSingleOffer();
  }, [GetSingleOffer]);

  useEffect(() => {
    if (offer) {
      setValue("offer_promotions_icon", offer.offer_promotions_icon);
      setValue(
        "offer_promotions_percentage",
        offer.offer_promotions_percentage
      );
      setValue(
        "offer_promotions_short_title",
        offer.offer_promotions_short_title
      );
      setValue(
        "offer_promotions_short_description",
        offer.offer_promotions_short_description
      );
    }
  }, [offer]);

  const options = icons.map((icon) => {
    return { label: icon.label, id: icon.value, iconfile: icon.icons };
  });
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props}>
                {
                  <img
                    alt="icon"
                    width={18}
                    style={{ marginRight: 10 }}
                    src={BonneApartApis.ImagesList + "icons/" + option.iconfile}
                  />
                }{" "}
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.offer_promotions_icon ? true : false}
                {...register("offer_promotions_icon", { required: false })}
                label="Search Icon"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="offer-promotions-percentage"
            label="Offer & Promotions Percentage (eg: 20%)"
            variant="outlined"
            autoFocus={true}
            error={errors.offer_promotions_percentage ? true : false}
            {...register("offer_promotions_percentage", {
              required: true,
            })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id="offer-promotions-about-payment"
            label="Offer & Promotions Short Title (eg: Down Payment)"
            variant="outlined"
            error={errors.offer_promotions_short_title ? true : false}
            {...register("offer_promotions_short_title", {
              required: true,
            })}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id="offer-promotions-about-payment"
            multiline
            label="Offer & Promotions Short Description"
            variant="outlined"
            error={errors.offer_promotions_short_description ? true : false}
            {...register("offer_promotions_short_description", {
              required: true,
            })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting ? true : false}
            color="primary"
          >
            {isSubmitting ? <FormLoader /> : <>Update Offer & Promotions</>}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
