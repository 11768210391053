import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function DeveloperForm({
  id,
  developer_name,
  developer_short_description,
}) {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue("developer_name", developer_name);
    setValue("developer_short_description", developer_short_description);
  }, [developer_name, developer_short_description, setValue]);

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          fullWidth
          id="developer_name"
          label="Developer Name"
          variant="outlined"
          autoFocus={true}
          error={errors.developer_name ? true : false}
          {...register("developer_name", { required: true })}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <TextField
          fullWidth
          id="developer_short_description"
          label="About Developer"
          multiline={true}
          variant="outlined"
          error={errors.developer_short_description ? true : false}
          {...register("developer_short_description", { required: true })}
        />
      </Grid>
    </>
  );
}
