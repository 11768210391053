import { Grid, Typography } from "@mui/material";
import React from "react";
import GlobalFileUploader from "../Utils/Uploaders/GlobalFileUploader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TextForm from "../Utils/TextForm";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export default function BNAeditbloginnerfields({ blog }) {
  const [aboutprojectdetail, setAboutProjectDetail] = useState("");
  const [aboutprojectdetaildynamic, setAboutProjectDetailDynamic] =
    useState("");
  const { setValue } = useFormContext();
  const handleOnChange = (editor) => {
    const data = editor.getData();

    if (data === "") {
      return toast.error("Blog Detail is required");
    }
    setAboutProjectDetail(data);
  };

  useEffect(() => {
    setValue("blog_detail", aboutprojectdetail);
    setValue("blog_title", blog.blog_title);
    setValue("blog_short_description", blog.blog_short_description);
    setAboutProjectDetailDynamic(blog.blog_description);
  }, [aboutprojectdetail, setValue, blog]);

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" mb={1}>
            Blog Image <span style={{ color: "red" }}>* (1100 x 700px)</span>
          </Typography>
          <GlobalFileUploader requiredfield={false} name={"blog_image"} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextForm
            isrequired={true}
            name={"blog_title"}
            label={"Blog Title"}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TextForm
            isrequired={true}
            name={"blog_short_description"}
            label={"Blog Short Description"}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <CKEditor
            editor={ClassicEditor}
            data={aboutprojectdetaildynamic || ""}
            onChange={(event, editor) => handleOnChange(editor)}
          />
        </Grid>
      </Grid>
    </>
  );
}
