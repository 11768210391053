import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import InnerPageComponent from "../Utils/InnerPageComponent";
import EditBNAofferandpromotioninnerfields from "./EditBNAofferandpromotioninnerfields";
import { useParams } from "react-router-dom";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import { Button, Grid } from "@mui/material";
import FormLoader from "../Loader/FormLoader";
import { useNavigate } from "react-router-dom";

export default function EditBNAofferandpromotions() {
  const methods = useForm();
  const navigate = useNavigate();
  const [offer, setOffer] = useState("");
  const { id } = useParams();
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.UpdateBnaOffer + "/" + id,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        navigate("/edit-bna-property/" + offer.property_id);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const CallforSigleofferandpromotion = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetSingleOfferandPromotion + "/" + id
        );
        if (response.data.status === "success") {
          setOffer(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallforSigleofferandpromotion();
  }, []);

  return (
    <>
      <InnerPageComponent title="Edit BNA Offer and Promotions" />
      <Box
        margin="20px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="50%"
          sx={{
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <EditBNAofferandpromotioninnerfields offer={offer} />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mt={2}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Update Offer & Promotions"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}
