import { Grid, Typography } from "@mui/material";
import React from "react";
import TextForm from "../Utils/TextForm";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function BNAlocationInnerFields({ location }) {
  const { setValue } = useFormContext();
  useEffect(() => {
    setValue("google_map_url", location?.google_map_url);
    setValue("address", location?.address);
  }, [location, setValue]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6">
            Google Map Url (https://www.google.com/maps/embed?pb=...)
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextForm
            name={"google_map_url"}
            label={"Google Map Url"}
            isrequired={true}
            autofocus={true}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextForm name={"address"} label={"Address"} isrequired={true} />
        </Grid>
      </Grid>
    </>
  );
}
