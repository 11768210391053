import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import FormLoader from "shared/components/Loader/FormLoader";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function AddProjectType() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await Instance.post(BonneApartApis.SaveProjectType, data)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            reset();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Box>
        <InnerPageComponent title="Save Project Type" />
      </Box>

      <Box mt={10}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            maxWidth="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="50%"
              sx={{
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box>
                      <TextField
                        id="project-type"
                        label="Project Type"
                        variant="outlined"
                        fullWidth
                        error={errors.project_type ? true : false}
                        {...register("project_type", { required: true })}
                      />
                    </Box>
                    <Box mt={2} textAlign="right">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting ? true : false}
                      >
                        {isSubmitting ? <FormLoader /> : "Save Project Type"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}
