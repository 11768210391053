import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useFormContext } from "react-hook-form";

export default function BackgroundImageFileUploader({ requiredfield }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [backgroundimage, setbackgroundimage] = useState(null);
  const handleBackgroundFileChange = (e) => {
    setbackgroundimage(e.target.files[0]);
  };

  const handlebackgroundimageRemove = () => {
    document.getElementById("background-image").value = "";
    setbackgroundimage(null);
  };
  return (
    <>
      <Box
        border={backgroundimage ? "2px solid #00ab55" : "1px solid #c4c4c4"}
        height={150}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        bgcolor={backgroundimage ? "#00ab5514" : "#fff"}
        sx={
          backgroundimage
            ? {}
            : {
                "&:hover": {
                  border: "1px solid #000",
                },
              }
        }
      >
        <Box>
          <Button
            variant="outlined"
            component="label"
            onChange={(e) => handleBackgroundFileChange(e)}
            color={`${errors.background_image ? "error" : "primary"}`}
          >
            <CameraAltOutlinedIcon
              sx={{ marginRight: "10px" }}
              fontSize="small"
            />
            {backgroundimage
              ? "Change Background Image (1500 x 900px)"
              : "Choose Background Image (1500 x 900px)"}

            <input
              hidden
              accept="image/*"
              type="file"
              id="background-image"
              {...register("background_image", { required: requiredfield })}
            />
          </Button>
        </Box>

        {/** Developer Logo Preview */}
        {backgroundimage && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="10px"
          >
            <Typography
              variant="body2"
              sx={{ marginRight: "10px" }}
              color="primary"
            >
              {backgroundimage.name}
            </Typography>
            <IconButton
              sx={{
                color: "dangercolor.main",
              }}
              onClick={() => handlebackgroundimageRemove()}
            >
              <ClearOutlinedIcon
                fontSize="small"
                sx={{
                  color: "dangercolor.main",
                }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
}
