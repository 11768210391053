import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import GeneralFormGerman from "./GeneralFormGerman";
import { useParams } from "react-router-dom";
import OfferandPromotionsDe from "./OfferandPromotionsDe";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import AboutProjectDe from "shared/components/BnaFormContext/BNAGermanForm/AboutProjectDe";
export default function AddPropertyForGerman() {
  const [singleBNAdeProperty, setsingleBNAdeProperty] = useState({});
  const { id: propertyId } = useParams();

  useEffect(() => {
    const CallForBNAGermanProperty = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetSinglePropertyDe + "/" + propertyId
        );

        if (response.data.status === "success") {
          setsingleBNAdeProperty(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBNAGermanProperty();
  }, []);

  // console.log(singleBNAdeProperty);

  return (
    <>
      <InnerPageComponent title="Add Property For German" />
      <Box sx={{ mt: 3 }}>
        <GeneralFormGerman id={propertyId} />
      </Box>
      <Box mt={3}>
        <OfferandPromotionsDe id={propertyId} deData={singleBNAdeProperty.id} />
      </Box>
      <Box mt={3}>
        <AboutProjectDe id={propertyId} deData={singleBNAdeProperty.id} />
      </Box>
    </>
  );
}
