import { Box } from "@mui/material";
import React from "react";
import GermanBlogForm from "shared/components/Blogs/German/GermanBlogForm";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function AddBlogForGerman() {
  return (
    <>
      <InnerPageComponent title="Add Blog" />
      <Box sx={{ mt: 3 }}>
        <GermanBlogForm />
      </Box>
    </>
  );
}
