import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import InteriorandExteriorFields from "./InteriorandExteriorFields";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import ExteriorFieldDyn from "./ExteriorFieldDyn";
import InteriorSkeleton from "../Skeleton/InteriorSkeleton";
import ExteriorSkeleton from "../Skeleton/ExteriorSkeleton";

export default function AddInteriorandExterior({ id }) {
  const [interiorImages, setInteriorImages] = useState([]);
  const [exteriorImages, setExteriorImages] = useState([]);
  const [loadinginterior, setLoadinginterior] = useState(true);
  const [loadingexterior, setLoadingexterior] = useState(true);
  const methods = useForm();
  const dynmethods = useForm();
  const onSubmit = async (data) => {
    console.log(data);

    const formData = new FormData();
    for (let i = 0; i < data.interior_images.length; i++) {
      formData.append("interior_images[]", data.interior_images[i]);
    }
    try {
      const response = await Instance.post(
        BonneApartApis.SaveInteriorImages + "/" + id,
        formData
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setInteriorImages(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // submit exterior images //

  const onExteriorSubmit = async (data) => {
    const formData = new FormData();
    for (let i = 0; i < data.exterior_images.length; i++) {
      formData.append("exterior_images[]", data.exterior_images[i]);
    }
    try {
      const response = await Instance.post(
        BonneApartApis.SaveExteriorImages + "/" + id,
        formData
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setExteriorImages(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleDelete = async (image) => {
    try {
      const response = await Instance.delete(
        BonneApartApis.DeleteInterior + "/" + image + "/" + id
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setInteriorImages([]);
        setInteriorImages(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleExteriorDelete = async (image) => {
    try {
      const response = await Instance.delete(
        BonneApartApis.DeleteExterior + "/" + image + "/" + id
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setExteriorImages([]);
        setExteriorImages(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const getInteriorImages = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetInteriorImages + "/" + id
        );
        if (response.data.status === "success") {
          setInteriorImages(response.data.data);
          setLoadinginterior(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getInteriorImages();
  }, [id]);

  // Exterior Images //

  useEffect(() => {
    const getExteriorImages = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetExteriorImages + "/" + id
        );
        if (response.data.status === "success") {
          setExteriorImages(response.data.data);
          setLoadingexterior(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getExteriorImages();
  }, [id]);

  return (
    <>
      <Box padding="20px 0">
        <Typography variant="h5">Interior and Exterior</Typography>
        {loadinginterior ? (
          <InteriorSkeleton />
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InteriorandExteriorFields
                savedimages={interiorImages}
                handleDelete={handleDelete}
              />
            </form>
          </FormProvider>
        )}

        {loadingexterior ? (
          <ExteriorSkeleton />
        ) : (
          <FormProvider {...dynmethods}>
            <form onSubmit={dynmethods.handleSubmit(onExteriorSubmit)}>
              <ExteriorFieldDyn
                savedimages={exteriorImages}
                handleExteriorDelete={handleExteriorDelete}
              />
            </form>
          </FormProvider>
        )}
      </Box>
    </>
  );
}
