import { Grid, Typography } from "@mui/material";
import React from "react";
import TextForm from "../Utils/TextForm";
import { useFormContext } from "react-hook-form";
import GlobalFileUploader from "../Utils/Uploaders/GlobalFileUploader";

export default function BNAQRCODEinnerFields() {
  const { setValue } = useFormContext();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6">Upload QR Code</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <GlobalFileUploader
            requiredfield={true}
            name={"qr_code"}
            size={"300 x 300px"}
          />
        </Grid>
      </Grid>
    </>
  );
}
