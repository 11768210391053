import { React, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar } from "@mui/material";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import CustomDropdownMenu from "../Utils/DynDropdownMenu";
import MuiIconsDynamic from "../Icons";
import LandingPageSkeleton from "../Skeleton/LandingPageSkeleton";

export default function SingleLandingPage() {
  const [rows, setrows] = useState([]);
  const [loading, setLoading] = useState(true);

  const blockProject = BonneApartApis.BlockProject;

  useEffect(() => {
    const callforProject = async () => {
      try {
        const response = await Instance.get(BonneApartApis.ProjectsList);
        if (response.data.status === "success") {
          setrows(response.data.projects);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };

    callforProject();
  }, []);

  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-landing-page/",
      type: "edit",
    },

    {
      name: "Block",
      icon: MuiIconsDynamic.BlockOutlinedIcon,
      url: blockProject,
      type: "block",
    },
  ];

  return (
    <>
      {loading ? (
        <LandingPageSkeleton />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.1)" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead bgcolor="#f4f6f8">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Developer</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    position: "relative",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.project_name}</TableCell>
                  <TableCell>
                    <Avatar
                      src={
                        BonneApartApis.ImagesList +
                        "backgroundimages/" +
                        row.background_image
                      }
                    />
                  </TableCell>
                  <TableCell>{row.project_type}</TableCell>
                  <TableCell>{row.developer_name}</TableCell>
                  <TableCell>{row.project_price}</TableCell>
                  <TableCell>
                    {row.status === "active" ? (
                      <Chip
                        label={row.status}
                        color="primary"
                        variant="outlined"
                      />
                    ) : (
                      <Chip
                        label={row.status}
                        color="error"
                        variant="outlined"
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <CustomDropdownMenu
                      position="relative"
                      MenuArray={MenuArray}
                      ItemId={row.id}
                      dynStatus={row.status}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
