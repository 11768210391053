import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import GermanBlogInnerFields from "./GermanBlogInnerFields";
import { useParams } from "react-router-dom";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import Instance from "shared/components/Utils/axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FormLoader from "shared/components/Loader/FormLoader";

export default function GermanBlogForm() {
  const { id: blogId } = useParams();
  const navigate = useNavigate();
  const [singleBlog, setsingleBlog] = useState({});
  const [singleBlogDe, setsingleBlogDe] = useState({});

  const methods = useForm();

  const isStateResponseNull =
    singleBlog === null && Object.keys(singleBlogDe).length === 0;

  const url = !singleBlogDe ? blogId : singleBlogDe?.id;

  const extraparam = !singleBlogDe ? "/en" : "/de";

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.SaveBNAblogDe + "/" + url + extraparam,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        navigate("/bna-blogs");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const CallForBlogs = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetsingleBNAblog + "/" + blogId
        );
        if (response.data.status === "success") {
          setsingleBlogDe(response.data.data.blog_de);
          setsingleBlog(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBlogs();
  }, []);

  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <Box
          width="50%"
          sx={{
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <GermanBlogInnerFields
                  blog={singleBlog}
                  de={singleBlog.blog_de}
                />

                <Grid item xs={12} md={12} lg={12}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={methods.formState.isSubmitting}
                    >
                      {methods.formState.isSubmitting ? (
                        <FormLoader />
                      ) : (
                        "Save Blog"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}
