import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import FormLoader from "../Loader/FormLoader";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";

export default function GoogleMapUrl({ id }) {
  const [googlemapurl, setGooglemapurl] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.SaveGoogleMap + "/" + id,
        data
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setGooglemapurl("");
        setGooglemapurl(response.data.data);
        reset();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const getGoogleMapUrl = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetGoogleMapUrl + "/" + id
        );
        if (response.data.status === "success") {
          setGooglemapurl(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getGoogleMapUrl();
  }, [reset]);

  return (
    <>
      <Box padding="20px 0">
        <Typography variant="h5">
          Google Map Url (https://www.google.com/maps/embed?pb=...)
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8} lg={8}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Google Map Url"
                        variant="outlined"
                        {...register("google_map_url", {
                          required: true,
                        })}
                        error={errors.google_map_url ? true : false}
                        multiline
                      />
                    </Box>
                    <Box mt={2} textAlign={"center"}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <FormLoader /> : "Save Google Map Url"}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box>
                      <Typography variant="h6">Google map Preview</Typography>
                      <iframe
                        title="Google Map"
                        src={googlemapurl}
                        width="100%"
                        style={{ border: "none", marginTop: "10px" }}
                        allowFullScreen=""
                        className="h-[400px] md:h-[600px]"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
}
