import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React from "react";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextForm from "../Utils/TextForm";

export default function BNAeditlocationpointsinnerfields() {
  const [icons, setIcons] = useState([]);
  const {
    register,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    const CallforIcons = async () => {
      await Instance.get(BonneApartApis.GetIconsForSelect).then((response) => {
        setIcons(response.data.data);
      });
    };
    CallforIcons();
  }, []);
  const options = icons.map((icon) => {
    return { label: icon.label, id: icon.value, iconfile: icon.icons };
  });

  return (
    <>
      <Grid conatiner spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props}>
                {
                  <img
                    alt="icon"
                    width={18}
                    style={{ marginRight: 10 }}
                    src={BonneApartApis.ImagesList + "icons/" + option.iconfile}
                  />
                }{" "}
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.location_points_icon ? true : false}
                {...register("location_points_icon", {
                  required: false,
                })}
                label="Search Icon"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
          <TextForm
            label={"Location Points Title"}
            name={"location_points_title"}
            isrequired={true}
            autofocus={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
          <TextForm
            label={"Location Points Time"}
            name={"location_points_time"}
            isrequired={true}
          />
        </Grid>
      </Grid>
    </>
  );
}
