import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import WelcomeComponent from "shared/components/Dashboard/WelcomeComponent";

export default function Dashboard() {
  return (
    <>
      <Box ml="20px">
        <Grid container gap={4}>
          {/* Welcome Component */}
          <WelcomeComponent />
        </Grid>
      </Box>
    </>
  );
}
