import { Box } from "@mui/material";
import React from "react";
import NetherlandBlogForm from "shared/components/Blogs/Netherland/NetherlandBlogForm";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function AddBlogForNetherland() {
  return (
    <>
      <InnerPageComponent title="Add Blog" />
      <Box sx={{ mt: 3 }}>
        <NetherlandBlogForm />
      </Box>
    </>
  );
}
