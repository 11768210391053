import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import DynSelect from "../Utils/DynSelec";
import BonneApartApis from "../Utils/bonneApartApis";

export default function EditProjectFields({ project }) {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const ApiCall = BonneApartApis.ShowProjectTypeForSelect;
  const ApiCall2 = BonneApartApis.GetDeveloperforSelect;

  useEffect(() => {
    setValue("project_price", project.project_price);
    setValue("project_name", project.project_name);
    setValue("project_type", project.project_type);
    setValue("developer_name", project.developer_name);
    setValue("project_short_description", project.project_short_description);
    setValue("project_keywords", project.project_keywords);
  }, [setValue, project]);

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-price"
          label="Project Price"
          variant="outlined"
          autoFocus={true}
          fullWidth
          {...register("project_price", { required: true })}
          error={errors.project_price ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-name"
          label="Project Name"
          variant="outlined"
          autoFocus={true}
          fullWidth
          {...register("project_name", { required: true })}
          error={errors.project_name ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-short-description"
          label="Project Short Description"
          variant="outlined"
          fullWidth
          {...register("project_short_description", {
            required: true,
          })}
          error={errors.project_short_description ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-keywords"
          label="Project Keywords"
          variant="outlined"
          fullWidth
          {...register("project_keywords", {
            required: true,
          })}
          error={errors.project_keywords ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DynSelect
          name={"project_type"}
          label="Project Type eg:(Villa, Apartment)"
          MenuArray={ApiCall}
          defaultValue={project.project_type}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DynSelect
          name={"developer_name"}
          label="Developer Name eg:(Emaar, Damac)"
          MenuArray={ApiCall2}
          defaultValue={project.developer_name}
        />
      </Grid>
    </>
  );
}
