import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormLoader from "shared/components/Loader/FormLoader";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function EditAmenity() {
  const { id } = useParams();
  const [icons, setIcons] = useState([]);
  const [amenity, setAmenity] = useState();

  const navigate = useNavigate();

  const GetAllIcons = BonneApartApis.GetIconsForSelect;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    await Instance.post(BonneApartApis.UpdateAmenity + "/" + id, data).then(
      (response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          navigate("/show-amenities");
        } else {
          toast.error(response.data.message);
        }
      },

      (error) => {
        toast.error(error.response.data.message);
      }
    );
  };

  const callforicons = async () => {
    const CallforIcons = async () => {
      await Instance.get(GetAllIcons).then((response) => {
        setIcons(response.data.data);
      });
    };
    CallforIcons();
  };

  useEffect(() => {
    const getamenitydata = async () => {
      await Instance.get(BonneApartApis.GetAmenityById + "/" + id).then(
        (response) => {
          setAmenity(response.data.data);
          setValue("amenity_name", response.data.data.amenity_name);
          setValue(
            "amenity_name_germen",
            response.data.data.amenity_name_germen
          );
        }
      );
    };
    getamenitydata();
  }, [GetAllIcons, setValue, id]);
  const options = icons.map((icon) => {
    return { label: icon.label, id: icon.value, iconfile: icon.icons };
  });

  return (
    <>
      <InnerPageComponent title="Edit Amenity" />

      <Box mt={10}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            maxWidth="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="50%"
              sx={{
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              <Box>
                <Box mb={2}>
                  <img
                    src={
                      BonneApartApis.ImagesList + "icons/" + amenity?.icon_image
                    }
                    alt="icon"
                  />
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="combo-box-demo"
                      options={options}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          {
                            <img
                              alt="icon"
                              width={18}
                              style={{ marginRight: 10 }}
                              src={
                                BonneApartApis.ImagesList +
                                "icons/" +
                                option.iconfile
                              }
                            />
                          }{" "}
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.amenity_icon ? true : false}
                          {...register("amenity_icon", {
                            required: false,
                          })}
                          label="Search Icon"
                          onFocus={() => {
                            callforicons();
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      label="Amenity Name"
                      {...register("amenity_name", {
                        required: true,
                      })}
                      autoFocus={true}
                      error={errors.amenity_name ? true : false}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      label="Amenity Name Germen"
                      {...register("amenity_name_germen", {
                        required: true,
                      })}
                      autoFocus={true}
                      error={errors.amenity_name_germen ? true : false}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <FormLoader /> : <>Update Amenity</>}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}
