import { Box } from "@mui/material";
import React from "react";
import Sidemenu from "./utils/Sidemenu";
import { useSelector } from "react-redux";

export default function Layout({ children }) {
  const { token } = useSelector((state) => state.user);

  return (
    <>
      <Box display={"flex"} minHeight={"100%"}>
        {
          // if token is present then show sidemenu
          token && <Sidemenu />
        }
        <Box flexGrow={1} padding={"40px 16px"} width={"calc(100% - 280px)"}>
          {children}
        </Box>
      </Box>
    </>
  );
}
