import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/system";
import { toast } from "react-hot-toast";
import FormLoader from "../Loader/FormLoader";
import BonneApartApis from "../Utils/bonneApartApis";

export default function AboutProjectFields({ datadyn }) {
  const [aboutprojectdetail, setAboutProjectDetail] = useState("");
  const [aboutprojectdetaildyn, setAboutProjectDetailDyn] = useState();
  const {
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const handleOnChange = (editor) => {
    const data = editor.getData();

    if (data === "") {
      return toast.error("About Project Description is required");
    }
    setAboutProjectDetail(data);
  };

  useEffect(() => {
    setValue("about_project_description", aboutprojectdetail);
    setAboutProjectDetailDyn(datadyn);
  }, [aboutprojectdetail, setValue, datadyn]);
  return (
    <>
      <Typography variant="h5">About Project</Typography>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant="h6" mb={1}>
            About Project Image <span style={{ color: "red" }}>*</span>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                type="file"
                fullWidth
                error={errors.about_project_image ? true : false}
                {...register("about_project_image", {
                  required: aboutprojectdetaildyn ? false : true,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="About Project Title"
                fullWidth
                error={errors.about_project_title ? true : false}
                {...register("about_project_title", { required: true })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6" mb={1}>
                About Project Description{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <CKEditor
                editor={ClassicEditor}
                data={aboutprojectdetaildyn?.about_project_description}
                onChange={(event, editor) => handleOnChange(editor)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting ? true : false}
              >
                {isSubmitting ? (
                  <FormLoader />
                ) : (
                  <>
                    {aboutprojectdetaildyn
                      ? "Update About Project"
                      : "Save About Project"}
                  </>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="h6" mb={1}>
            About Project Image Preview
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "200px",
              border: "1px solid #ddd",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <img
              src={
                BonneApartApis.ImagesList +
                "aboutprojectimages/" +
                aboutprojectdetaildyn?.about_project_image
              }
              alt="about project"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          <Typography variant="body1" mb={1} mt={1}>
            {aboutprojectdetaildyn?.about_project_title}
          </Typography>

          <Typography variant="h6" mb={1} mt={1}>
            About Project Description
          </Typography>

          <Typography variant="body1" mb={1}>
            {aboutprojectdetaildyn?.about_project_description}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
