import {
  Avatar,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import MuiIconsDynamic from "shared/components/Icons";
import FormLoader from "shared/components/Loader/FormLoader";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import CustomDropdownMenu from "shared/components/Utils/DynDropdownMenu";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function Properties() {
  const [rows, setrows] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const CallForBNAProperties = async () => {
      try {
        await Instance.get(BonneApartApis.PropertiesList).then((response) => {
          if (response.data.status === "success") {
            setrows(response.data.data);
            setLoading(false);
          }
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBNAProperties();
  }, []);

  const blockProperty = BonneApartApis.BlockProperty;
  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-bna-property/",
      type: "edit",
    },

    {
      name: "Block",
      icon: MuiIconsDynamic.BlockOutlinedIcon,
      url: blockProperty,
      type: "block",
    },
  ];

  return (
    <>
      <InnerPageComponent
        title="Bonne Apart Properties"
        button={true}
        url="/add-bna-property"
        buttontext="Add Property"
      />
      {loading ? (
        <FormLoader />
      ) : (
        <Box mt={3}>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.1)" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="Bna Properties Table">
              <TableHead bgcolor="#f4f6f8">
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Developer</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      position: "relative",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.project_name}</TableCell>
                    <TableCell>
                      <Avatar
                        src={
                          BonneApartApis.ImagesList +
                          "bnabackgroundimages/" +
                          row.background_image
                        }
                      />
                    </TableCell>
                    <TableCell>{row.project_type}</TableCell>
                    <TableCell>{row.developer_name}</TableCell>
                    <TableCell>{row.project_price}</TableCell>
                    <TableCell>
                      <Link to={`/add-bna-property-german/${row.id}`}>
                        <Button variant="text" size="small">
                          Add German
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {row.status === "active" ? (
                        <Chip
                          label={row.status}
                          color="primary"
                          variant="outlined"
                        />
                      ) : (
                        <Chip
                          label={row.status}
                          color="error"
                          variant="outlined"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <CustomDropdownMenu
                        position="relative"
                        MenuArray={MenuArray}
                        ItemId={row.id}
                        dynStatus={row.status}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}
