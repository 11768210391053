import { Grid, TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

export default function EmailSignatureForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          label="Image Url"
          type={"text"}
          variant="outlined"
          fullWidth
          {...register("image_url", { required: true })}
          error={errors.image_url ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          label="Agent Name"
          type={"text"}
          variant="outlined"
          fullWidth
          {...register("agent_name", { required: true })}
          error={errors.agent_name ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          label="Designation"
          type={"text"}
          variant="outlined"
          fullWidth
          {...register("designation", { required: true })}
          error={errors.designation ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          label="Agent Mobile"
          type={"text"}
          variant="outlined"
          fullWidth
          {...register("mobile", { required: true })}
          error={errors.mobile ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          label="Agent Email"
          type={"text"}
          variant="outlined"
          fullWidth
          {...register("agent_email", { required: true })}
          error={errors.agent_email ? true : false}
        />
      </Grid>
    </>
  );
}
