import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import OfferandPromotionsSkeleton from "../Skeleton/OfferandPromotionsSkeleton";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import OfferandPromotionsFields from "./OfferandPromotionsFields";

export default function AddOfferandPromotions({ id }) {
  const [loading, setLoading] = useState(true);
  const methods = useForm();
  // how to call reset method /

  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const callforoffers = async () => {
      try {
        await Instance.get(BonneApartApis.GetOffers + "/" + id).then(
          (response) => {
            if (response.data.status === "success") {
              setOffers([]);
              setOffers(response.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    callforoffers();
  }, []);

  const onSubmit = async (data) => {
    try {
      await Instance.post(BonneApartApis.SaveOffer + "/" + id, data).then(
        (response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            setOffers([]);
            setOffers(response.data.data);
            methods.reset();
          } else {
            toast.error(response.data.message);
          }
        }
      );
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Box
        padding="20px 0"
        paperprops={{
          id: "offerandpromotions",
        }}
      >
        <Typography variant="h5">Offers & Promotions</Typography>
        {loading ? (
          <OfferandPromotionsSkeleton />
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <OfferandPromotionsFields offerlist={offers} />
            </form>
          </FormProvider>
        )}
      </Box>
    </>
  );
}
