const Types = {
  morning: "Good morning",
  afternoon: "Good afternoon",
  evening: "Good evening",
  night: "Good night",
};

const Greetings = () => {
  const date = new Date();
  const hour = date.getHours();
  if (hour < 12) {
    return Types.morning;
  } else if (hour < 18) {
    return Types.afternoon;
  } else if (hour < 22) {
    return Types.evening;
  } else {
    return Types.night;
  }
};

export default Greetings;
