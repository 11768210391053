import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  container: {
    padding: "1rem",
  },

  palette: {
    primary: {
      main: "#00ab55",
      light: "#00ab5514",
      darkbackground: "#e0fae6",
      darktext: "#005249",
    },
    secondary: {
      main: "#F5F5F5",
    },
    lightgrey: {
      main: "#919eab1f",
      light: "#919eab14",
    },
    grey: {
      main: "#637381",
    },
    gold: {
      main: "#e2b359",
    },
    dangercolor: {
      main: "#ff0000",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "0.75rem",
      fontWeight: 600,
    },
    span: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            fontSize: "0.9rem",
            fontWeight: 400,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0.5rem 1rem",
          fontSize: "0.9rem",
          borderRadius: "8px",
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: "8px",
          padding: "0.5rem 1rem",
          fontSize: "0.9rem",
          fontWeight: 500,
        },

        contained: {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          textTransform: "capitalize",
          "&:hover": {
            boxShadow: "0px 6px 10px rgb(2 119 59 / 30%)",
          },
        },
      },
    },
  },
});

export default theme;
