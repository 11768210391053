import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import CircleRoundedIcon from "@mui/icons-material/AdjustOutlined";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import Utils from "@mui/icons-material/WorkspacesOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SubjectIcon from "@mui/icons-material/Subject";

const MuiIconsDynamic = {
  LayersOutlinedIcon,
  DashboardOutlinedIcon,
  AddHomeOutlinedIcon,
  LogoutOutlinedIcon,
  MoreVertOutlinedIcon,
  EditOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  BlockOutlinedIcon,
  BrushOutlinedIcon,
  SquareFootOutlinedIcon,
  PrecisionManufacturingOutlinedIcon,
  CameraAltOutlinedIcon,
  ForestOutlinedIcon,
  CircleRoundedIcon,
  ExpandMoreRoundedIcon,
  ExpandLessRoundedIcon,
  Utils,
  BedOutlinedIcon,
  LanguageOutlinedIcon,
  MapOutlinedIcon,
  SubjectIcon,
};

export default MuiIconsDynamic;
