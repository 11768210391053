import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";
import BackgroundImageFileUploader from "../Dashboard/FileUploaders/BackgroundImageFileUploader";
import DeveloperFileUploader from "../Dashboard/FileUploaders/DeveloperFileUploader";
import FormLoader from "../Loader/FormLoader";
import LandingPageEditSkeleton from "../Skeleton/LandingPageEditSkeleton";
import Instance from "./axios";
import BonneApartApis from "./bonneApartApis";
import EditProjectFields from "./EditProjectFields";

export default function EditProjectFormContext({ id }) {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState("");
  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      console.log(data);
      const formData = new FormData();
      formData.append("project_price", data.project_price);
      formData.append("project_name", data.project_name);
      formData.append("project_type", data.project_type);
      formData.append("developer_name", data.developer_name);
      formData.append(
        "project_short_description",
        data.project_short_description
      );
      formData.append("project_keywords", data.project_keywords);
      formData.append("developer_logo", data.developer_logo[0]);
      formData.append("background_image", data.background_image[0]);

      const response = await Instance.post(
        BonneApartApis.UpdateProject + "/" + id,
        formData
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const callforProject = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetProject + "/" + id
        );
        if (response.data.status === "success") {
          setProject(response.data.project);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    callforProject();
  }, []);

  return (
    <>
      {loading ? (
        <LandingPageEditSkeleton />
      ) : (
        <FormProvider {...methods}>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <EditProjectFields project={project} />
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <DeveloperFileUploader requiredfield={false} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BackgroundImageFileUploader requiredfield={false} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={methods.formState.isSubmitting}
                    >
                      {methods.formState.isSubmitting ? (
                        <FormLoader />
                      ) : (
                        " Update General Information"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
