import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import FormLoader from "shared/components/Loader/FormLoader";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import TextForm from "shared/components/Utils/TextForm";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function AddPropertyArea() {
  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.AddPropertyArea,
        data
      );
      if (response.data.status === "success") {
        methods.reset();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <InnerPageComponent title="Add Property Area" />

      <Box mt={5}>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            width="50%"
            sx={{
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextForm
                      label={"Property Area"}
                      type={"text"}
                      name={"propertyarea"}
                      isrequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"end"}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={methods.formState.isSubmitting}
                    >
                      {methods.formState.isSubmitting ? (
                        <FormLoader />
                      ) : (
                        "Save Property Area"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </Box>
    </>
  );
}
