import { Grid } from "@mui/material";
import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function ExteriorSkeleton() {
  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton
                variant="text"
                sx={{ width: "200px" }}
                animation="wave"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: "80px", borderRadius: "4px" }}
                animation="wave"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent="center"
            >
              <Skeleton
                variant="rectangular"
                sx={{ width: "150px", height: "41px", borderRadius: "8px" }}
                animation="wave"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
