import { Grid } from "@mui/material";
import React from "react";
import MultipleImageUploader from "../Utils/Uploaders/MultipleFileUploader";
import DynSelect from "../Utils/DynSelec";
import BonneApartApis from "../Utils/bonneApartApis";

export default function BNAfloorplanInnerFields() {
  const ApiCall = BonneApartApis.GetBedroomsList;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <DynSelect
            name={"floorplans"}
            label="1 Bedroom, 2 Bedroom, 3 Bedroom etc"
            MenuArray={ApiCall}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MultipleImageUploader filename={"floor_plans_images"} />
        </Grid>
      </Grid>
    </>
  );
}
