import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import StaticGermanForm from "shared/components/BnaFormContext/BNAGermanForm/StaticGermanForm";
import FormLoader from "shared/components/Loader/FormLoader";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function GeneralFormGerman({ id }) {
  const [singleProperty, setsingleProperty] = useState({});
  const [singlePropertyDe, setsinglePropertyDe] = useState({});
  const methods = useForm();

  useEffect(() => {
    const CallforProperty = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetSingleProperty + "/" + id
        );

        if (response.data.status === "success") {
          setsingleProperty(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    CallforProperty();
  }, []);

  useEffect(() => {
    const CallforGermanProperty = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetSinglePropertyDe + "/" + id
        );

        if (response.data.status === "success") {
          setsinglePropertyDe(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setsinglePropertyDe({});
      }
    };
    CallforGermanProperty();
  }, []);

  const isStateResponseNull = Object.keys(singlePropertyDe).length === 0;
  console.log(isStateResponseNull);

  const url = isStateResponseNull ? id : singlePropertyDe.id;
  const extraparam = isStateResponseNull ? "/en" : "/de";

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.UpdatePropertyDe + "/" + url + extraparam,
        data
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <StaticGermanForm
            data={isStateResponseNull ? singleProperty : singlePropertyDe}
            de={singlePropertyDe ? true : false}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              type="submit"
              disabled={methods.formState.isSubmitting}
            >
              {methods.formState.isSubmitting ? (
                <FormLoader />
              ) : (
                "Update Property"
              )}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
