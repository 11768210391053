import { Box } from "@mui/system";
import React from "react";
import SingleLandingPage from "./SingleLandingPage";

export default function LandingPages() {
  return (
    <Box>
      <SingleLandingPage />
    </Box>
  );
}
