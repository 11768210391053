import React from "react";
import MultipleImageUploader from "../Utils/Uploaders/MultipleFileUploader";
import { Grid } from "@mui/material";

export default function BNAexteriorandexteriorInnerFields() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <MultipleImageUploader filename={"bna_exterior_images"} />
        </Grid>
      </Grid>
    </>
  );
}
