import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextForm from "shared/components/Utils/TextForm";

export default function StaticGermanForm({ data, de }) {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (de) {
      setValue("project_name", data.project_name);
      setValue("project_short_description", data.project_short_description);
    }
    setValue("project_name", data.project_name);
    setValue("project_price", data.project_price);
    setValue("project_type", data.project_type);
    setValue("developer_name", data.developer_name);
    setValue("project_area", data.project_area);
    setValue("project_location", data.project_location);
    setValue("mobile_background", data.mobile_background);
    setValue("background_image", data.background_image);
  }, [data]);

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"project_price"}
              isrequired={true}
              autofocus={true}
              isReadOnly={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"project_name"}
              isrequired={true}
              authoFocus={true}
              isReadOnly={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"project_short_description"}
              label={"Project Short Description"}
              isrequired={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"project_type"}
              isrequired={true}
              isReadOnly={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"developer_name"}
              isrequired={true}
              isReadOnly={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"project_area"}
              isrequired={true}
              isReadOnly={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"project_location"}
              isrequired={true}
              isReadOnly={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"mobile_background"}
              isrequired={true}
              isReadOnly={true}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextForm
              type={"text"}
              name={"background_image"}
              isrequired={true}
              isReadOnly={true}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
