import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BackgroundImageFileUploader from "../Dashboard/FileUploaders/BackgroundImageFileUploader";
import BonneApartApis from "../Utils/bonneApartApis";
import DynSelect from "../Utils/DynSelec";
import TextForm from "../Utils/TextForm";
import { useSelector } from "react-redux";
import Instance from "../Utils/axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FormLoader from "../Loader/FormLoader";
import GlobalFileUploader from "../Utils/Uploaders/GlobalFileUploader";

export default function GeneralForm({ property }) {
  const ApiCall = BonneApartApis.ShowProjectTypeForSelect;
  const ApiCall2 = BonneApartApis.GetDeveloperforSelect;
  const Projectareapi = BonneApartApis.ShowPropertyAreaForSelect;
  const methods = useForm();

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  const onSubmit = async (data) => {
    const userid = user.id;
    try {
      const formData = new FormData();
      formData.append("project_price", data.project_price);
      formData.append("project_name", data.project_name);
      formData.append(
        "project_short_description",
        data.project_short_description
      );
      formData.append("project_type", data.project_type);
      formData.append("developer_name", data.developer_name);
      formData.append("project_area", data.project_area);
      formData.append("project_location", data.project_location);
      formData.append("background_image", data.background_image[0]);
      formData.append("mobile_background", data.mobile_background[0]);
      await Instance.post(
        BonneApartApis.SaveBnaProperty + "/" + userid,
        formData
      ).then(
        (response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            navigate("/edit-bna-property/" + response.data.id);
          } else {
            toast.error(response.data.message);
          }
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <Typography variant="h5">General Information</Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextForm
                      type={"text"}
                      name={"project_price"}
                      label={"Project Price"}
                      isrequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextForm
                      type={"text"}
                      name={"project_name"}
                      label={"Project Name"}
                      isrequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextForm
                      type={"text"}
                      name={"project_short_description"}
                      label={"Project Short Description"}
                      isrequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <DynSelect
                      name={"project_type"}
                      label="Project Type eg:(Villa, Apartment)"
                      MenuArray={ApiCall}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <DynSelect
                      name={"developer_name"}
                      label="Developer Name eg:(Emaar, Damac)"
                      MenuArray={ApiCall2}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <TextForm
                      type={"text"}
                      name={"project_area"}
                      label={"Project Size eg:(1000) only numbers"}
                      isrequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <DynSelect
                      name={"project_location"}
                      label="Project Area"
                      MenuArray={Projectareapi}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <BackgroundImageFileUploader requiredfield={true} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <GlobalFileUploader
                      requiredfield={true}
                      name={"mobile_background"}
                      size={"500 x 600px"}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  mt={2}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Save & Continue"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
