import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAfloorplanInnerFields from "./BNAfloorplanInnerFields";
import FormLoader from "../Loader/FormLoader";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import FloorPlansSlider from "../Utils/Slider/FloorPlansSlider";

export default function BNAfloorplans({ property }) {
  const methods = useForm();
  const [savedimages, setSavedimages] = useState([]);
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("property_id", property.id);
      for (let i = 0; i < data.floor_plans_images.length; i++) {
        formData.append("floor_plans_images[]", data.floor_plans_images[i]);
      }
      formData.append("floorplans", data.floorplans);
      const response = await Instance.post(
        BonneApartApis.SaveFloorPlanImages + "/" + property.id,
        formData
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setSavedimages(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    const CallforFloorplanImages = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetFloorPlanImages + "/" + property.id
        );
        if (response.data.status === "success") {
          setSavedimages(response.data.data);
        }
      } catch (error) {}
    };
    CallforFloorplanImages();
  }, [property.id]);

  const handleDelete = async (id) => {
    try {
      const response = await Instance.delete(
        BonneApartApis.DeleteFloorPlanImages + "/" + id
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">
          Floor Plans {property.project_name}{" "}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
              <BNAfloorplanInnerFields />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  mt={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Save Floor Plans"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FloorPlansSlider
                savedimages={savedimages}
                path={"bnafloorplanimages/"}
                handleDelete={handleDelete}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
