import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAlocationpointsinnerfields from "./BNAlocationpointsinnerfields";
import FormLoader from "../Loader/FormLoader";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import CustomDropdownMenu from "../Utils/DynDropdownMenu";
import MuiIconsDynamic from "../Icons";

export default function BNAlocationpoints({ property }) {
  const [locationpoints, setLocationPoints] = useState([]);
  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.SaveBnaLocationPoints + "/" + property.id,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setLocationPoints(response.data.data);
        methods.reset();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const CallForBNAlocationpoints = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetBnaLocationPoints + "/" + property.id
        );
        if (response.data.status === "success") {
          setLocationPoints(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBNAlocationpoints();
  }, [property.id]);

  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-bna-location-ponits/",
      type: "edit",
    },

    {
      name: "Delete",
      icon: MuiIconsDynamic.DeleteOutlineOutlinedIcon,
      url: "/delete-bna-location-ponits/",
      type: "delete",
    },
  ];

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">
          Location Points {property.project_name}{" "}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAlocationpointsinnerfields />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} mt={2} textAlign={"center"}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Save Location Points"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2}>
              {locationpoints.map((locationpoint, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="hide-box"
                >
                  <Box
                    bgcolor="secondary.main"
                    position="relative"
                    sx={{
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <img
                      width={30}
                      src={
                        BonneApartApis.ImagesList +
                        "icons/" +
                        locationpoint.location_points_icon.icon
                      }
                      alt="offer"
                    />
                    <Typography variant="h6">
                      {locationpoint.location_points_title}
                    </Typography>
                    <Typography variant="body2">
                      {locationpoint.location_points_time}
                    </Typography>

                    <CustomDropdownMenu
                      position="absolute"
                      MenuArray={MenuArray}
                      ItemId={locationpoint.id}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
