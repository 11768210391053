import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import EmailSignatureForm from "./EmailSignatureForm";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import FormLoader from "../Loader/FormLoader";

export default function EmailSignatureFormContext() {
  const methods = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.SaveEmailSignature,
        data
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);

        navigate("/email-signature");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <EmailSignatureForm />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  type="submit"
                  disabled={methods.formState.isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  {methods.formState.isSubmitting ? (
                    <FormLoader />
                  ) : (
                    <>Save Email Signature</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </FormProvider>
    </>
  );
}
