import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import MuiIconsDynamic from "shared/components/Icons";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DevelopersSkeleton from "shared/components/Skeleton/DevelopersSkeleton";

export default function AddDevelopers() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(true);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = ({ id, url }) => {
    if (typeof id === "number" && url === "/block-developer") {
      try {
        confirmAlert({
          title: "Confirmation",
          message: "Are you sure you want to block this developer?",
          buttons: [
            {
              label: "Yes",
              onClick: () =>
                Instance.get(BonneApartApis.BlockDeveloper + "/" + id)
                  .then((res) => {
                    if (res.data.status === "success") {
                      toast.success(res.data.message);
                      setDevelopers([]);
                      setDevelopers(res.data.data);
                    } else {
                      toast.error(res.data.message);
                    }
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message);
                  }),
            },
            {
              label: "No",
              onClick: () => console.log("No"),
            },
          ],
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else if (typeof id === "number" && url === "/edit-developer") {
      navigate("/edit-developer/" + id);
    }

    setAnchorEl(null);
  };

  const MenuList = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-developer",
    },

    {
      name: "Block",
      icon: MuiIconsDynamic.BlockOutlinedIcon,
      url: "/block-developer",
    },
  ];

  // Api Call //

  useEffect(() => {
    Instance.get(BonneApartApis.GetDevelopers)
      .then((res) => {
        if (res.data.status === "success") {
          setDevelopers(res.data.data);
          setLoading(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  return (
    <>
      <InnerPageComponent
        title="Developers List"
        button={true}
        buttontext="Add Developer"
        url="/save-developer"
      />
      {loading ? (
        <DevelopersSkeleton />
      ) : (
        <Box>
          <Box mt={5}>
            <Grid container spacing={2}>
              {developers.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box
                    border="1px solid #F5F5F5"
                    borderRadius="8px"
                    padding="30px 20px"
                    textAlign="center"
                    display="inline-flex"
                    height="100%"
                    justifyContent="center"
                    flexDirection="column"
                    gap={2}
                    alignItems="center"
                    position="relative"
                  >
                    <Box>
                      <img
                        src={BonneApartApis.ImagesList + item.developer_logo}
                        width={60}
                        alt="Logo"
                      />
                    </Box>
                    <Box>
                      <Typography variant="h4">
                        {item.developer_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        {item.developer_short_description}
                      </Typography>
                    </Box>
                    <Box position="absolute" right="10px" top="10px">
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MuiIconsDynamic.MoreVertOutlinedIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={{
                          "& .MuiPaper-root": {
                            boxShadow: "0px 3px 10px 0px #0000000f",
                            borderRadius: "10px",
                            padding: "10px",
                          },
                        }}
                        PaperProps={{
                          style: {
                            maxHeight: 48 * 4.5,
                            width: "15ch",
                          },
                        }}
                      >
                        {MenuList.map(
                          (option, index) =>
                            option.url && (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  handleClose({ id: item.id, url: option.url })
                                }
                                style={
                                  option.name === "Delete"
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                <option.icon
                                  fontSize="small"
                                  sx={{ mr: "10px" }}
                                />
                                {option.name}
                              </MenuItem>
                            )
                        )}
                      </Menu>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
}
