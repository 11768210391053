import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import BNAblogsinnerfields from "shared/components/Blogs/BNAblogsinnerfields";
import FormLoader from "shared/components/Loader/FormLoader";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { useNavigate } from "react-router-dom";

export default function AddBlog() {
  const methods = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("blog_title", data.blog_title);
      formData.append("blog_short_description", data.blog_short_description);
      formData.append("blog_image", data.blog_image[0]);
      formData.append("blog_detail", data.blog_detail);
      const response = await Instance.post(BonneApartApis.AddBlogs, formData);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        navigate("/bna-blogs");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <Box mb={3}>
        <InnerPageComponent title="Add Blog" />
      </Box>

      <Box>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <BNAblogsinnerfields />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12} mt={2} textAlign={"end"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={methods.formState.isSubmitting}
                    >
                      {methods.formState.isSubmitting ? (
                        <FormLoader />
                      ) : (
                        "Save Blog"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    </>
  );
}
