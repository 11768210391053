import { Grid, TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

export default function SimpleForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          id="icon-name"
          label="Icon Name"
          variant="outlined"
          fullWidth
          {...register("icon_name", { required: true })}
          error={errors.icon_name ? true : false}
        />
      </Grid>
    </>
  );
}
