import { Box, IconButton, Typography } from "@mui/material";
import { useState, React } from "react";
import Avatar from "@mui/material/Avatar";
import Images from "shared/assets/images";
import { Link } from "react-router-dom";
import { logout } from "shared/portal/slices/authentication/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// Mui Icons //
import MuiIconsDynamic from "shared/components/Icons";

export default function Sidemenu() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [sidemenu, setSidemenu] = useState([
    {
      name: "Dashboard",
      icon: MuiIconsDynamic.DashboardOutlinedIcon,
      link: "/dashboard",
      isActive: true,
    },
    {
      name: "Bonne Apart Website",
      icon: MuiIconsDynamic.LanguageOutlinedIcon,
      isActive: false,
      isDropdown: false,
      subCategories: [
        {
          name: "Properties",
          link: "/bna-properties",
          isActive: false,
          icon: MuiIconsDynamic.CircleRoundedIcon,
        },
        {
          name: "Blogs",
          link: "/bna-blogs",
          isActive: false,
          icon: MuiIconsDynamic.CircleRoundedIcon,
        },
      ],
    },
    {
      name: "Landing Pages",
      icon: MuiIconsDynamic.AddHomeOutlinedIcon,
      isActive: false,
      isDropdown: false,
      subCategories: [
        {
          name: "Landing Pages",
          link: "/landing-page",
          isActive: false,
          icon: MuiIconsDynamic.CircleRoundedIcon,
        },
      ],
    },
    {
      name: "Utils",
      icon: MuiIconsDynamic.Utils,
      isActive: false,
      isDropdown: false,
      subCategories: [
        {
          name: "Developers",
          icon: MuiIconsDynamic.PrecisionManufacturingOutlinedIcon,
          link: "/show-developers",
          isActive: false,
        },
        {
          name: "Project Types",
          icon: MuiIconsDynamic.LayersOutlinedIcon,
          link: "/show-project-type",
          isActive: false,
        },
        {
          name: "Add Property Areas",
          icon: MuiIconsDynamic.MapOutlinedIcon,
          link: "/show-property-areas",
          isActive: false,
        },
        {
          name: "Icons",
          icon: MuiIconsDynamic.BrushOutlinedIcon,
          link: "/icons",
          isActive: false,
        },

        {
          name: "Amenities",
          icon: MuiIconsDynamic.ForestOutlinedIcon,
          link: "/show-amenities",
          isActive: false,
        },
        {
          name: "Bedrooms",
          icon: MuiIconsDynamic.BedOutlinedIcon,
          link: "/show-bedrooms",
          isActive: false,
        },
      ],
    },
    {
      name: "Bonne Apart Utils",
      icon: MuiIconsDynamic.Utils,
      isActive: false,
      isDropdown: false,
      subCategories: [
        {
          name: "Email Signatures",
          icon: MuiIconsDynamic.SubjectIcon,
          link: "/email-signature",
          isActive: false,
        },
      ],
    },
  ]);

  const updateActiveState = (index) => {
    const newSidemenu = sidemenu.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isActive: true,
          isDropdown: true,
        };
      } else {
        return {
          ...item,
          isActive: false,
          isDropdown: false,
        };
      }
    });
    setSidemenu(newSidemenu);
  };

  return (
    <>
      <Box
        flexShrink={0}
        width={280}
        padding={"20px"}
        borderRight="2px dotted #F5F5F5"
      >
        {/* <Box>
        <img src={Images.Logo} width={60} alt="Logo" />
      </Box> */}
        <Box>
          <Box
            bgcolor="lightgrey.main"
            height={40}
            padding="20px 10px"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            borderRadius={3}
            mt={2}
          >
            <Box minWidth="25%">
              <Avatar alt="Avatar" src={Images.Avatar} />
            </Box>
            <Box minWidth="45%">
              <Typography variant="h3">{user?.name}</Typography>
              <Typography variant="body2">Admin</Typography>
            </Box>
            <Box minWidth="30%" display="flex" justifyContent="flex-end">
              <IconButton onClick={() => dispatch(logout())}>
                <MuiIconsDynamic.LogoutOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="body2" color="grey.main" mt={2}>
            General
          </Typography>
        </Box>

        <Box mt={2}>
          {sidemenu.map((item, index) => (
            <Box key={index}>
              <Link
                onClick={() => updateActiveState(index)}
                style={{ textDecoration: "none" }}
                to={item.link}
              >
                <Box
                  bgcolor={`${
                    item.isActive === true ? "primary.light" : "transparent"
                  }`}
                  height={50}
                  padding="0px 12px 0px 16px"
                  borderRadius="8px"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  cursor="pointer"
                  sx={
                    item.isActive === true
                      ? ""
                      : {
                          "&:hover": {
                            backgroundColor: "lightgrey.light",
                          },
                        }
                  }
                >
                  <Box display="flex" justifyContent="center" columnGap={3}>
                    <item.icon
                      fontSize="small"
                      sx={{
                        color: `${
                          item.isActive === true ? "primary.main" : "grey.main"
                        }`,
                      }}
                    />
                    <Box>
                      <Typography
                        variant="body2"
                        fontWeight={`${
                          item.isActive === true ? "bold" : "normal"
                        }`}
                        color={`${
                          item.isActive === true ? "primary.main" : "grey.main"
                        }`}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    {item.subCategories && (
                      <IconButton
                        sx={{
                          color: `${
                            item.isActive === true
                              ? "primary.main"
                              : "grey.main"
                          }`,
                        }}
                      >
                        {item?.isDropdown ? (
                          <MuiIconsDynamic.ExpandLessRoundedIcon />
                        ) : (
                          <MuiIconsDynamic.ExpandMoreRoundedIcon />
                        )}
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Link>
              {item.isDropdown && (
                <Box>
                  {item.subCategories?.map((subItem, subIndex) => (
                    <Box key={subIndex}>
                      <Link
                        to={subItem.link}
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          display="flex"
                          padding="20px 20px 10px 60px"
                          alignItems="center"
                        >
                          <subItem.icon
                            fontSize="xs"
                            sx={{
                              color: `${
                                subItem.isActive === true
                                  ? "primary.main"
                                  : "grey.main"
                              }`,
                            }}
                          />
                          <Typography variant="body2" ml={1} color="grey.main">
                            {subItem.name}
                          </Typography>
                        </Box>
                      </Link>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
