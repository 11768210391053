import { TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function TextForm({
  type,
  name,
  label,
  isrequired,
  value,
  multiline,
  autofocus,
  isReadOnly,
}) {
  const { register, formState, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, value);
  }, [value, name, setValue]);

  return (
    <>
      <TextField
        label={label}
        multiline={multiline}
        fullWidth
        variant="outlined"
        type={type}
        name={name}
        InputProps={{
          readOnly: isReadOnly,
        }}
        autoFocus={autofocus}
        {...register(name, { required: isrequired })}
        error={formState.errors[name] ? true : false}
      />
      {formState.errors[name] && (
        <Typography variant="caption" color="error">
          {formState.errors[name].message}
        </Typography>
      )}
    </>
  );
}
