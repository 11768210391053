import { Grid, Skeleton } from "@mui/material";
import React from "react";

export default function DevelopersSkeleton() {
  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              display="flex"
              justifyContent="center"
            >
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: "304px", borderRadius: "8px" }}
                animation="wave"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              display="flex"
              justifyContent="center"
            >
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: "304px", borderRadius: "8px" }}
                animation="wave"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              display="flex"
              justifyContent="center"
            >
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: "304px", borderRadius: "8px" }}
                animation="wave"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              display="flex"
              justifyContent="center"
            >
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: "304px", borderRadius: "8px" }}
                animation="wave"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
