import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import BNAUploadQRCode from "shared/components/BnaFormContext/BNAUploadQRCode";
import BNAaboutproperty from "shared/components/BnaFormContext/BNAaboutproperty";
import BNAamenities from "shared/components/BnaFormContext/BNAamenities";
import BNAexterior from "shared/components/BnaFormContext/BNAexterior";
import BNAfloorplans from "shared/components/BnaFormContext/BNAfloorplans";
import BNAinteriorandexterior from "shared/components/BnaFormContext/BNAinteriorandexterior";
import BNAlocation from "shared/components/BnaFormContext/BNAlocation";
import BNAlocationpoints from "shared/components/BnaFormContext/BNAlocationpoints";
import BNAofferandpromotions from "shared/components/BnaFormContext/BNAofferandpromotions";
import UpdateGeneralForm from "shared/components/BnaFormContext/UpdateGeneralForm";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function EditBNAproperty() {
  const [property, seProperty] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const CallForEdirProperty = async () => {
      try {
        await Instance.get(BonneApartApis.GetSingleProperty + "/" + id).then(
          (response) => {
            if (response.data.status === "success") {
              seProperty(response.data.data);
            }
          }
        );
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForEdirProperty();
  }, [id]);

  return (
    <>
      <Box mb={3}>
        <InnerPageComponent title="Edit Property" />
      </Box>
      <UpdateGeneralForm property={property} />
      <BNAUploadQRCode property={property} />
      <BNAofferandpromotions property={property} />
      <BNAaboutproperty property={property} />
      <BNAfloorplans property={property} />
      <BNAamenities property={property} />
      <BNAinteriorandexterior property={property} />
      <BNAexterior property={property} />
      <BNAlocation property={property} />
      <BNAlocationpoints property={property} />
    </>
  );
}
