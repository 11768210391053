import { Box } from "@mui/system";
import React from "react";
import GeneralForm from "shared/components/BnaFormContext/GeneralForm";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";

export default function AddProperty() {
  return (
    <>
      <InnerPageComponent title="Add Property" />
      <Box mt={3}>
        <GeneralForm />
      </Box>
    </>
  );
}
