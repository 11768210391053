import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import { useParams } from "react-router-dom";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DeveloperForm from "shared/components/Developers/DeveloperForm";
import DeveloperFileUploader from "shared/components/Dashboard/FileUploaders/DeveloperFileUploader";
import FormLoader from "shared/components/Loader/FormLoader";

export default function EditDeveloper() {
  const { id } = useParams();
  const [developer, setDeveloper] = useState();
  const navigate = useNavigate();

  const methods = useForm();

  const callforDeveloper = async () => {
    try {
      const response = await Instance.get(
        BonneApartApis.GetSingleDeveloper + "/" + id
      );
      if (response.data.status === "success") {
        setDeveloper(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    callforDeveloper();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("developer_name", data.developer_name);
      formData.append(
        "developer_short_description",
        data.developer_short_description
      );
      formData.append("developer_logo", data.developer_logo[0]);
      const response = await Instance.post(
        BonneApartApis.UpdateDeveloper + "/" + id,
        formData
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        navigate("/show-developers");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <InnerPageComponent
        title="Edit List"
        button={true}
        buttontext="Add Developer"
        url="/save-developer"
      />

      <Box>
        <Box mt={10}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box
                maxWidth="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  width="50%"
                  sx={{
                    "@media (max-width: 600px)": {
                      width: "100%",
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    <DeveloperForm {...developer} />
                    <Grid item xs={12} md={12} lg={12}>
                      <DeveloperFileUploader requiredfield={false} />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} textAlign="right">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={methods.formState.isSubmitting ? true : false}
                      >
                        {methods.formState.isSubmitting ? (
                          <FormLoader />
                        ) : (
                          "Update Developer"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}
