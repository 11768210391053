import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAinteriorandexteriorInnerFields from "./BNAinteriorandexteriorInnerFields";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import Instance from "../Utils/axios";
import BNAinteriorSlider from "../Utils/Slider/BNAinteriorSlider";
import FormLoader from "../Loader/FormLoader";

export default function BNAinteriorandexterior({ property }) {
  const [interiorimages, setInteriorImages] = useState([]);

  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("property_id", property.id);
      for (let i = 0; i < data.bna_inerior_images.length; i++) {
        formData.append("bna_inerior_images[]", data.bna_inerior_images[i]);
      }
      const response = await Instance.post(
        BonneApartApis.BNAsaveinteriorimages + "/" + property.id,
        formData
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setInteriorImages(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await Instance.delete(
        BonneApartApis.BNAdeleteinteriorimages + "/" + id
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setInteriorImages(interiorimages.filter((image) => image.id !== id));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const getInteriorImages = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.BNAgetinteriorimages + "/" + property.id
        );
        if (response.data.status === "success") {
          setInteriorImages(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getInteriorImages();
  }, [property.id]);

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">
          Interior & Exterior {property.project_name}{" "}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAinteriorandexteriorInnerFields />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} mt={2} textAlign={"center"}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Save Interior Images"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6">Interior Images</Typography>

            <BNAinteriorSlider
              savedimages={interiorimages}
              path={"bnainteriorimages/"}
              handleDelete={handleDelete}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
