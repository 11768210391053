import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";

export default function AmenityInputFields() {
  const [icons, setIcons] = useState([]);
  const GetAllIcons = BonneApartApis.GetIconsForSelect;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const callforicons = async () => {
    const CallforIcons = async () => {
      await Instance.get(GetAllIcons).then((response) => {
        setIcons(response.data.data);
      });
    };
    CallforIcons();
  };

  const options = icons.map((icon) => {
    return { label: icon.label, id: icon.value, iconfile: icon.icons };
  });
  // reset AutoComplete field //
  // useEffect(() => {
  //   setValue("amenity_name", "");

  //   if (isSubmitted) {
  //     reset();
  //   }
  // }, [isSubmitted, reset, setValue]);

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Autocomplete
          fullWidth
          disablePortal
          id="combo-box-demo"
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <li {...props}>
              {
                <img
                  alt="icon"
                  width={18}
                  style={{ marginRight: 10 }}
                  src={BonneApartApis.ImagesList + "icons/" + option.iconfile}
                />
              }{" "}
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors.amenity_icon ? true : false}
              {...register("amenity_icon", {
                required: true,
              })}
              label="Search Icon"
              onFocus={() => {
                callforicons();
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          fullWidth
          error={errors.amenity_name ? true : false}
          {...register("amenity_name", {
            required: true,
          })}
          label="Amenity Name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          fullWidth
          error={errors.amenity_name_germen ? true : false}
          {...register("amenity_name_germen", {
            required: true,
          })}
          label="Amenity Name Germen"
          variant="outlined"
        />
      </Grid>
    </>
  );
}
