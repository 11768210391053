import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { useForm } from "react-hook-form";
import FormLoader from "../Loader/FormLoader";

export default function AmenitiesandAdvanatges({ id }) {
  const [amenities, setAmenities] = useState([]);
  const [submittedAmenities, setSubmittedAmenities] = useState([]);
  // load amenities and advantages from the database //

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    const callforamenities = async () => {
      try {
        await Instance.get(BonneApartApis.GetAmenities).then((response) => {
          if (response.data.status === "success") {
            setAmenities(response.data.data);
          } else {
            toast.error(response.data.message);
          }
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    callforamenities();
  }, []);

  const onSubmit = async (data) => {
    try {
      await Instance.post(
        BonneApartApis.SaveAmenitiesForProject + "/" + id,
        data
      ).then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          setSubmittedAmenities(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const callforamenitiesforproject = async () => {
      try {
        await Instance.get(
          BonneApartApis.GetAmenitiesForProject + "/" + id
        ).then((response) => {
          if (response.data.status === "success") {
            setSubmittedAmenities(response.data.data);
          } else {
            toast.error(response.data.message);
          }
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    callforamenitiesforproject();
  }, [id, setValue]);

  useEffect(() => {
    if (submittedAmenities.length > 0) {
      const amenityIds = submittedAmenities.map((item) => item.amenity_id);
      setValue("amenity_name", amenityIds);
    }
  }, [submittedAmenities, setValue]);

  return (
    <>
      <Box padding="20px 0">
        <Typography variant="h5">Amenities and Advantages</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container spacing={2}>
                  {amenities.map((item, index) => (
                    <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                      <Box key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...register("amenity_name[]", {
                                required: true,
                              })}
                              value={item.id}
                            />
                          }
                          label={item.amenity_name}
                        />
                      </Box>
                    </Grid>
                  ))}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {errors.amenity_name && (
                      <Typography variant="caption" color="error">
                        Please select at least one amenity
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <FormLoader /> : "Save Amenities"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
}
