import { Route, Routes } from "react-router-dom";
import Login from "screens/Authentication/Login";
import AddProperty from "screens/BonneApart/AddProperty";
import AddBlog from "screens/BonneApart/Blogs/AddBlog";
import Blogs from "screens/BonneApart/Blogs/Blogs";
import EditBlog from "screens/BonneApart/Blogs/EditBlog";
import AddBlogForGerman from "screens/BonneApart/Blogs/German/AddBlogForGerman";
import AddBlogForNetherland from "screens/BonneApart/Blogs/Netherland/AddBlogForNetherland";
import EditBNAproperty from "screens/BonneApart/EditBNAproperty";
import AddPropertyForGerman from "screens/BonneApart/German/AddPropertyForGerman";
import Properties from "screens/BonneApart/Properties";
import Dashboard from "screens/Dashboard";
import LandingPage from "screens/LandingPage";
import AddLandingPage from "screens/LandingPage/AddLandingPage";
import EditLandingPage from "screens/LandingPage/EditLandingPage";
import Amenities from "screens/Utils/Amenities";
import EditAmenity from "screens/Utils/Amenities/EditAmenity";
import SaveAmenity from "screens/Utils/Amenities/SaveAmenity";
import Bedrooms from "screens/Utils/Bedrooms";
import Addbedroom from "screens/Utils/Bedrooms/Addbedroom";
import AddEmailSignature from "screens/Utils/BonneApartUtils/AddEmailSignature";
import EmailSignature from "screens/Utils/BonneApartUtils/EmailSignature";
import GenerateEmailSignature from "screens/Utils/BonneApartUtils/GenerateEmailSignature";
import EditDeveloper from "screens/Utils/EditDeveloper";
import AddIcon from "screens/Utils/Icons/AddIcon";
import ShowIcons from "screens/Utils/Icons/ShowIcons";
import AddPropertyArea from "screens/Utils/Locations/AddPropertyArea";
import PropertyArea from "screens/Utils/Locations/PropertyArea";
import AddProjectType from "screens/Utils/ProjectType/AddProjectType";
import EditProjectType from "screens/Utils/ProjectType/EditProjectType";
import ShowProjectType from "screens/Utils/ProjectType/ShowProjectType";
import SaveDeveloper from "screens/Utils/SaveDeveloper";
import ShowDevelopers from "screens/Utils/ShowDevelopers";
import EditBNAofferandpromotionsForGerman from "shared/components/BnaFormContext/BNAGermanForm/EditBNAofferandpromotionsForGerman";
import EditBNAlocationpoints from "shared/components/BnaFormContext/EditBNAlocationpoints";
import EditBNAofferandpromotions from "shared/components/BnaFormContext/EditBNAofferandpromotions";
import EditEmailSignatureComponent from "shared/components/BonneapartUtils/EditEmailSignatureComponent";
import EditOffer from "shared/components/LandingPage/EditOffer";
import AuthenticateUser from "shared/security/AuthenticateUser";

export const AppRoutes = {
  login: { path: "/", component: Login },
  dashboard: {
    path: "/dashboard",
    component: Dashboard,
    isAuthenticated: true,
  },
  landingpage: {
    path: "/landing-page",
    component: LandingPage,
    isAuthenticated: true,
  },
  addlandingpage: {
    path: "/add-landing-page",
    component: AddLandingPage,
    isAuthenticated: true,
  },
  editlandingpage: {
    path: "/edit-landing-page/:id",
    component: EditLandingPage,
    isAuthenticated: true,
  },
  showdevelopers: {
    path: "/show-developers",
    component: ShowDevelopers,
    isAuthenticated: true,
  },
  savedeveloper: {
    path: "/save-developer",
    component: SaveDeveloper,
    isAuthenticated: true,
  },
  editdeveloper: {
    path: "/edit-developer/:id",
    component: EditDeveloper,
    isAuthenticated: true,
  },
  showicons: {
    path: "/icons",
    component: ShowIcons,
    isAuthenticated: true,
  },
  addicon: {
    path: "/add-icon",
    component: AddIcon,
    isAuthenticated: true,
  },
  showprojecttype: {
    path: "/show-project-type",
    component: ShowProjectType,
    isAuthenticated: true,
  },
  saveprojecttype: {
    path: "/save-project-type",
    component: AddProjectType,
    isAuthenticated: true,
  },
  editprojecttype: {
    path: "/edit-project-type/:id",
    component: EditProjectType,
    isAuthenticated: true,
  },
  editoffer: {
    path: "/edit-offer/:id",
    component: EditOffer,
    isAuthenticated: true,
  },
  showamenities: {
    path: "/show-amenities",
    component: Amenities,
    isAuthenticated: true,
  },
  newamneties: {
    path: "/new-amenity",
    component: SaveAmenity,
    isAuthenticated: true,
  },
  editamenity: {
    path: "/edit-amenity/:id",
    component: EditAmenity,
    isAuthenticated: true,
  },
  showbedrooms: {
    path: "/show-bedrooms",
    component: Bedrooms,
    isAuthenticated: true,
  },
  addbedroom: {
    path: "/add-bedroom",
    component: Addbedroom,
    isAuthenticated: true,
  },
  bnaproperties: {
    path: "/bna-properties",
    component: Properties,
    isAuthenticated: true,
  },
  addbnaproperty: {
    path: "/add-bna-property",
    component: AddProperty,
    isAuthenticated: true,
  },
  editbnaproperty: {
    path: "/edit-bna-property/:id",
    component: EditBNAproperty,
    isAuthenticated: true,
  },
  editbnaofferandpromotion: {
    path: "/edit-bna-offer-and-promotion/:id",
    component: EditBNAofferandpromotions,
    isAuthenticated: true,
  },
  editbnalocationpoints: {
    path: "/edit-bna-location-ponits/:id",
    component: EditBNAlocationpoints,
    isAuthenticated: true,
  },
  bnablogs: {
    path: "/bna-blogs",
    component: Blogs,
    isAuthenticated: true,
  },
  addbnablog: {
    path: "/add-bna-blog",
    component: AddBlog,
    isAuthenticated: true,
  },
  editbnablog: {
    path: "/edit-bna-blog/:id",
    component: EditBlog,
    isAuthenticated: true,
  },
  showpropertyareas: {
    path: "/show-property-areas",
    component: PropertyArea,
    isAuthenticated: true,
  },
  addpropertyarea: {
    path: "/add-property-area",
    component: AddPropertyArea,
    isAuthenticated: true,
  },

  emailsignature: {
    path: "/email-signature",
    component: EmailSignature,
    isAuthenticated: true,
  },

  addemailsignature: {
    path: "/add-email-signature",
    component: AddEmailSignature,
    isAuthenticated: true,
  },

  generateemailsignature: {
    path: "/generate-email-signature/:id",
    component: GenerateEmailSignature,
    isAuthenticated: true,
  },

  editemailsignature: {
    path: "/edit-email-signature/:id",
    component: EditEmailSignatureComponent,
    isAuthenticated: true,
  },

  addbnapropertyforgerman: {
    path: "/add-bna-property-german/:id",
    component: AddPropertyForGerman,
    isAuthenticated: true,
  },

  editbnaofferandpromotionforgerman: {
    path: "/edit-bna-offer-and-promotion-de/:id",
    component: EditBNAofferandpromotionsForGerman,
    isAuthenticated: true,
  },

  addblogingerman: {
    path: "/add-bna-blog-german/:id",
    component: AddBlogForGerman,
    isAuthenticated: true,
  },

  addbloginnetherland: {
    path: "/add-bna-blog-netherland/:id",
    component: AddBlogForNetherland,
    isAuthenticated: true,
  },
};

const WebRoutes = () => {
  return (
    <Routes>
      {Object.keys(AppRoutes).map((route, index) => {
        const {
          path,
          component: Component,
          isAuthenticated,
        } = AppRoutes[route];
        return (
          <Route
            key={`app-routes-${index}`}
            path={path}
            element={
              isAuthenticated ? (
                <AuthenticateUser element={Component} />
              ) : (
                <Component />
              )
            }
          />
        );
      })}
    </Routes>
  );
};

export default WebRoutes;
