import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAeditbloginnerfields from "shared/components/Blogs/BNAeditbloginnerfields";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import { useParams } from "react-router-dom";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import Instance from "shared/components/Utils/axios";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import FormLoader from "shared/components/Loader/FormLoader";
import { useNavigate } from "react-router-dom";

export default function EditBlog() {
  const [blog, setBlog] = useState({});
  const methods = useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("blog_title", data.blog_title);
      formData.append("blog_short_description", data.blog_short_description);
      formData.append("blog_detail", data.blog_detail);
      formData.append("blog_image", data.blog_image[0]);

      const response = await Instance.post(
        BonneApartApis.UpdateBnaBlog + "/" + id,
        formData
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        navigate("/bna-blogs");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const CallforBlog = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetsingleBNAblog + "/" + id
        );

        if (response.data.status === "success") {
          setBlog(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallforBlog();
  }, [id]);

  return (
    <>
      <Box>
        <InnerPageComponent title="Edit Blog" />
      </Box>

      <Box display={"flex"} justifyContent={"center"}>
        <Box
          width="50%"
          sx={{
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAeditbloginnerfields blog={blog} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} mt={2} textAlign={"center"}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Update Blog"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}
