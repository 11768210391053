import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DeveloperFileUploader from "shared/components/Dashboard/FileUploaders/DeveloperFileUploader";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import { useForm, FormProvider } from "react-hook-form";
import DeveloperForm from "shared/components/Developers/DeveloperForm";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import FormLoader from "shared/components/Loader/FormLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function SaveDeveloper() {
  const methods = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("developer_name", data.developer_name);
      formData.append(
        "developer_short_description",
        data.developer_short_description
      );
      formData.append("developer_logo", data.developer_logo[0]);

      await Instance.post(BonneApartApis.SaveDeveloper, formData).then(
        (response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            methods.reset();
            navigate("/show-developers");
          } else {
            response.data.status === "error" &&
              toast.error(response.data.message);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <InnerPageComponent title="Add Developer" />
      <Box mt={10}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              maxWidth="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                width="50%"
                sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <DeveloperForm />
                  <Grid item xs={12} md={12} lg={12}>
                    <DeveloperFileUploader requiredfield={true} />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} textAlign="right">
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={methods.formState.isSubmitting ? true : false}
                    >
                      {methods.formState.isSubmitting ? (
                        <FormLoader />
                      ) : (
                        "Save Developer"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}
