import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import MuiIconsDynamic from "../Icons";
import FormLoader from "../Loader/FormLoader";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import CustomDropdownMenu from "../Utils/DynDropdownMenu";
import BNAofferandpromotioninnerfields from "./BNAofferandpromotioninnerfields";

export default function BNAofferandpromotions({ property }) {
  const methods = useForm();
  const [offerlist, setOfferList] = useState([]);
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.SaveBnaOffer + "/" + property.id,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setOfferList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // Menu Array //

  const MenuArray = [
    {
      name: "Edit",
      icon: MuiIconsDynamic.EditOutlinedIcon,
      url: "/edit-bna-offer-and-promotion/",
      type: "edit",
    },

    {
      name: "Delete",
      icon: MuiIconsDynamic.DeleteOutlineOutlinedIcon,
      url: BonneApartApis.DeleteBnaOffer,
      type: "delete",
    },
  ];

  useEffect(() => {
    const CallForBNAofferandpromotions = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetBnaOffers + "/" + property.id
        );
        if (response.data.status === "success") {
          setOfferList(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBNAofferandpromotions();
  }, [property.id]);

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">Offers & Promotions</Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
              <BNAofferandpromotioninnerfields />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  mt={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Save Offer & Promotions"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography variant="h6">Offer & Promotions Preview</Typography>
              <Grid container spacing={2} pt={2}>
                {offerlist.map((offer, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    className="hide-box"
                  >
                    <Box
                      bgcolor="secondary.main"
                      position="relative"
                      sx={{
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      <img
                        width={30}
                        src={
                          BonneApartApis.ImagesList +
                          "icons/" +
                          offer.icon_image
                        }
                        alt="offer"
                      />
                      <Typography variant="h6">
                        {offer.offer_promotions_percentage}
                      </Typography>
                      <Typography variant="body2">
                        {offer.offer_promotions_short_title}
                      </Typography>
                      <Typography variant="body2">
                        {offer.offer_promotions_short_description}
                      </Typography>

                      <CustomDropdownMenu
                        position="absolute"
                        MenuArray={MenuArray}
                        ItemId={offer.id}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
