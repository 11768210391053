import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TextForm from "shared/components/Utils/TextForm";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";

export default function NetherlandnBlogInnerFields({ blog, nl }) {
  const [aboutprojectdetail, setAboutProjectDetail] = useState("");
  const [aboutprojectdetaildynamic, setAboutProjectDetailDynamic] =
    useState("");
  const { setValue } = useFormContext();

  const handleOnChange = (editor) => {
    const data = editor.getData();

    if (data === "") {
      return toast.error("Blog Detail is required");
    }
    setAboutProjectDetail(data);
  };

  useEffect(() => {
    setValue("blog_image", blog.blog_image);
    setValue("blog_detail", aboutprojectdetail);
  }, [blog, aboutprojectdetail, setValue]);

  useEffect(() => {
    if (nl) {
      setValue("blog_title", nl.blog_title);
      setValue("blog_short_description", nl.blog_short_description);
      setAboutProjectDetailDynamic(nl.blog_description);
    }
  }, [blog, nl]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" mb={1}>
          Blog Image <span style={{ color: "red" }}>* </span>
        </Typography>
        <TextForm isrequired={true} name={"blog_image"} isReadOnly={true} />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <TextForm isrequired={true} name={"blog_title"} label={"Blog Title"} />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <TextForm
          isrequired={true}
          name={"blog_short_description"}
          label={"Blog Short Description"}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <CKEditor
          editor={ClassicEditor}
          data={aboutprojectdetaildynamic || ""}
          onChange={(event, editor) => handleOnChange(editor)}
        />
      </Grid>
    </>
  );
}
