import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import BonneApartApis from "../bonneApartApis";
import MuiIconsDynamic from "shared/components/Icons";

const BNAexteriorSlider = ({ handleDelete, savedimages, path }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {savedimages.map((image, index) => {
          return (
            <Box key={index} position="relative">
              <LazyLoadImage
                src={BonneApartApis.ImagesList + path + image.exterior_images}
                alt="exterior"
                width="100%"
                height="140px"
                style={{
                  objectFit: "cover",
                }}
                effect="blur"
              />

              <IconButton
                onClick={() => handleDelete(image.id)}
                color="error"
                sx={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          );
        })}
      </Slider>
    </>
  );
};

export default BNAexteriorSlider;
