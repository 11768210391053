import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAlocationInnerFields from "./BNAlocationInnerFields";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";
import FormLoader from "../Loader/FormLoader";

export default function BNAlocation({ property }) {
  const methods = useForm();
  const [location, setLocation] = useState("");

  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.BNAsavelocation + "/" + property.id,
        data
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        methods.reset();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const Callforaddress = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.BNAgetAddress + "/" + property.id
        );

        if (response.data.status === "success") {
          setLocation(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    Callforaddress();
  }, [property.id]);

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">Location {property.project_name} </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAlocationInnerFields location={location} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} mt={2} textAlign={"center"}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : location ? (
                      "Update Location"
                    ) : (
                      "Save Location"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6">Google Map Url + Adress</Typography>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
