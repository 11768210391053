import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import FormLoader from "../Loader/FormLoader";
import MuiIconsDynamic from "../Icons";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

export default function EmailSignature() {
  const [emailSignature, setemailSignature] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const CallforEmailSignature = async () => {
      try {
        await Instance.get(BonneApartApis.GetEmailSignature).then(
          (response) => {
            setLoading(false);
            setemailSignature(response.data.data);
          }
        );
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    CallforEmailSignature();
  }, []);

  const handleGenerate = async (id) => {
    navigate("/generate-email-signature/" + id);
  };

  const handleDelete = async (id) => {
    try {
      await Instance.delete(
        BonneApartApis.DeleteEmailSignature + "/" + id
      ).then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);

          // filter email signature after delete //
          const filterEmailSignature = emailSignature.filter(
            (item) => item.id !== id
          );

          setemailSignature(filterEmailSignature);
        } else {
          toast.error(response.data.message);
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleDeleteConfirm = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete this email signature.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleEdit = (id) => {
    navigate("/edit-email-signature/" + id);
  };

  return (
    <>
      {loading ? (
        <FormLoader />
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.1)" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead bgcolor="#f4f6f8">
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>Signature</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {emailSignature.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      position: "relative",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.image}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.designation}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => handleGenerate(row.id)}
                        size="small"
                        color="primary"
                      >
                        Generate
                      </Button>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteConfirm(row.id)}
                      >
                        <MuiIconsDynamic.DeleteOutlineOutlinedIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(row.id)}
                      >
                        <MuiIconsDynamic.EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
