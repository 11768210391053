import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "theme";
import Layout from "shared/Layout";
import WebRoutes from "shared/routes/routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "shared/portal/slices";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <WebRoutes>
            <App />
          </WebRoutes>
          <Toaster />
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
