import { Grid, TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import DynSelect from "../Utils/DynSelec";
import BonneApartApis from "../Utils/bonneApartApis";

export default function GeneralForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const ApiCall = BonneApartApis.ShowProjectTypeForSelect;
  const ApiCall2 = BonneApartApis.GetDeveloperforSelect;

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-price"
          label="Project Price"
          type={"number"}
          variant="outlined"
          fullWidth
          {...register("project_price", { required: true })}
          error={errors.project_price ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-name"
          label="Project Name"
          variant="outlined"
          fullWidth
          {...register("project_name", { required: true })}
          error={errors.project_name ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-short-description"
          label="Project Short Description"
          variant="outlined"
          fullWidth
          {...register("project_short_description", {
            required: true,
          })}
          error={errors.project_short_description ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TextField
          id="project-keywords"
          label="Project Keywords"
          variant="outlined"
          fullWidth
          {...register("project_keywords", {
            required: true,
          })}
          error={errors.project_keywords ? true : false}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DynSelect
          name={"project_type"}
          label="Project Type eg:(Villa, Apartment)"
          MenuArray={ApiCall}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DynSelect
          name={"developer_name"}
          label="Developer Name eg:(Emaar, Damac)"
          MenuArray={ApiCall2}
        />
      </Grid>
    </>
  );
}
