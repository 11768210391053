import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import FormLoader from "../Loader/FormLoader";
import BNAQRCODEinnerFields from "./BNAQRCODEinnerFields";

export default function BNAUploadQRCode({ property }) {
  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("qr_code", data.qr_code[0]);

      const response = await Instance.post(
        BonneApartApis.UploadQRCode + "/" + property.id,
        formData
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        methods.reset();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h5">QRCODE {property.project_name} </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAQRCODEinnerFields />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} mt={2} textAlign={"center"}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Add QRCODE"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6">QRCODE</Typography>
            <img
              src={BonneApartApis.ImagesList + "qrcode/" + property.qr_code}
              alt="QRCODE"
              style={{ width: "200px" }} // 100% width
            />
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
