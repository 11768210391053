import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Instance from "./axios";

const { IconButton, Menu, MenuItem } = require("@mui/material");
const { Box } = require("@mui/system");
const { useState } = require("react");
const { confirmAlert } = require("react-confirm-alert");
const { default: MuiIconsDynamic } = require("../Icons");

const CustomDropdownMenu = ({ MenuArray, ItemId, position, dynStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBlock = ({ id, url }) => {
    try {
      url = dynStatus === "blocked" ? url + "-unblock" : url;
      Instance.get(url + "/" + id)
        .then((res) => {
          if (res.data.status === "success") {
            toast.success(res.data.message);
            setInterval(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleClose = ({ id, type, url }) => {
    if (typeof id === "number" && type === "block") {
      try {
        confirmAlert({
          title: "Confirmation",
          message: "Are you sure you want to block this developer?",
          buttons: [
            {
              label: "Yes",
              onClick: () => handleBlock({ id, url }),
            },
            {
              label: "No",
              onClick: () => console.log("No"),
            },
          ],
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else if (typeof id === "number" && type === "edit") {
      navigate(url + id);
    } else if (typeof id === "number" && type === "delete") {
      try {
        confirmAlert({
          title: "Confirmation",
          message: "Are you sure you want to delete this developer?",
          buttons: [
            {
              label: "Yes",
              onClick: async () => {
                try {
                  const res = await Instance.delete(url + "/" + id);
                  if (res.data.status === "success") {
                    toast.success(res.data.message);
                  }
                } catch (error) {
                  toast.error(error.response.data.message);
                }
              },
            },
            {
              label: "No",
              onClick: () => console.log("No"),
            },
          ],
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

    setAnchorEl(null);
  };
  return (
    <Box position={position} right="10px" top="10px">
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MuiIconsDynamic.MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0px 3px 10px 0px #0000000f",
            borderRadius: "10px",
            padding: "10px",
          },
        }}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "15ch",
          },
        }}
      >
        {MenuArray.map(
          (option, index) =>
            option.url && (
              <MenuItem
                key={index}
                onClick={() =>
                  handleClose({
                    id: ItemId,
                    type: option.type,
                    url: option.url,
                  })
                }
                style={option.name === "Delete" ? { color: "red" } : {}}
              >
                <option.icon fontSize="small" sx={{ mr: "10px" }} />
                {option.name === "Block" && dynStatus === "active"
                  ? "Block"
                  : option.name === "Block" && dynStatus === "blocked"
                  ? "Unblock"
                  : option.name}
              </MenuItem>
            )
        )}
      </Menu>
    </Box>
  );
};

export default CustomDropdownMenu;
