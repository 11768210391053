import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useFormContext } from "react-hook-form";

export default function GlobalFileUploader({ requiredfield, name, size }) {
  const {
    register,
    formState: { errors, isSubmitted },
  } = useFormContext();
  const [fileUploaderdyn, setfileUploaderdyn] = useState(null);

  const handleUploaderFileChange = (e) => {
    setfileUploaderdyn(e.target.files[0]);
  };
  const handlefileUploaderdynRemove = () => {
    document.getElementById("file-uploader-id-dyn").value = "";
    setfileUploaderdyn(null);
  };
  useEffect(() => {
    if (isSubmitted) {
      setfileUploaderdyn(null);
    }
  }, [isSubmitted]);

  return (
    <>
      <Box
        border={fileUploaderdyn ? "2px solid #00ab55" : "1px solid #c4c4c4"}
        height={150}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        bgcolor={fileUploaderdyn ? "#00ab5514" : "#fff"}
        sx={
          fileUploaderdyn
            ? {}
            : {
                "&:hover": {
                  border: "1px solid #000",
                },
              }
        }
      >
        <Box>
          <Button
            variant="outlined"
            component="label"
            onChange={(e) => handleUploaderFileChange(e)}
            color={`${errors.name ? "error" : "primary"}`}
          >
            <CameraAltOutlinedIcon
              sx={{ marginRight: "10px" }}
              fontSize="small"
            />
            {fileUploaderdyn
              ? `Change ${name?.split("_").join(" ")} ${
                  size ? size?.split("_").join() : ""
                }`
              : `Choose ${name?.split("_").join(" ")} ${
                  size ? size?.split("_").join() : ""
                }`}

            <input
              hidden={true}
              accept="image/png,image/jpg, image/jpg"
              type="file"
              id="file-uploader-id-dyn"
              {...register(name, { required: requiredfield })}
            />
          </Button>
        </Box>

        {/** Developer Logo Preview */}
        {fileUploaderdyn && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="10px"
          >
            <Typography
              variant="body2"
              sx={{ marginRight: "10px" }}
              color="primary"
            >
              {fileUploaderdyn.name}
            </Typography>
            <IconButton
              sx={{
                color: "dangercolor.main",
              }}
              onClick={() => handlefileUploaderdynRemove()}
            >
              <ClearOutlinedIcon
                fontSize="small"
                sx={{
                  color: "dangercolor.main",
                }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
}
