import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Instance from "./axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const DynSelect = ({ name, MenuArray, label, defaultValue }) => {
  const [projecttypes, setProjectTypes] = useState([]);
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleChange = () => {
    const getprojecttype = async () => {
      try {
        await Instance.get(MenuArray).then((response) => {
          if (response.data.status === "success") {
            setProjectTypes(response.data.data);
          } else {
            toast.error(response.data.message);
          }
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    getprojecttype();
  };

  // make array of objects from projecttypes //
  const options = projecttypes.map((projecttype) => {
    return { label: projecttype.label, id: projecttype.value };
  });

  // set the value of the select //
  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  return (
    <Autocomplete
      fullWidth
      disablePortal
      id="combo-box-demo"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...register(name, { required: true })}
          error={errors[name] ? true : false}
          onFocus={handleChange}
          // auto select the option coming from the database //
          label={label}
        />
      )}
    />
  );
};

export default DynSelect;
