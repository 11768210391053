import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import BNAeditlocationpointsinnerfields from "./BNAeditlocationpointsinnerfields";
import InnerPageComponent from "../Utils/InnerPageComponent";
import { useEffect } from "react";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";
import { useState } from "react";
import FormLoader from "../Loader/FormLoader";

export default function EditBNAlocationpoints() {
  const { id } = useParams();
  const [locationpoints, setLocationPoints] = useState([]);
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.UpdateBnaLocationPoints + "/" + id,
        data
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const methods = useForm();

  useEffect(() => {
    const CallForBNAlocationpoints = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetsingleBnaLocationPoints + "/" + id
        );
        if (response.data.status === "success") {
          setLocationPoints(response.data.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBNAlocationpoints();
  }, []);

  useEffect(() => {
    methods.setValue(
      "location_points_title",
      locationpoints.location_points_title
    );
    methods.setValue(
      "location_points_time",
      locationpoints.location_points_time
    );
  }, [locationpoints]);
  return (
    <>
      <Box mb={3}>
        <InnerPageComponent title="Edit BNA Location Points" />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Box
          width="50%"
          sx={{
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <BNAeditlocationpointsinnerfields />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      mt={2}
                      textAlign={"center"}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={methods.formState.isSubmitting}
                      >
                        {methods.formState.isSubmitting ? (
                          <FormLoader />
                        ) : (
                          "Update Location Point"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}
