import { Button, Grid } from "@mui/material";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BackgroundImageFileUploader from "./FileUploaders/BackgroundImageFileUploader";
import DeveloperFileUploader from "./FileUploaders/DeveloperFileUploader";
import GeneralForm from "./GeneralForm";
import Instance from "../Utils/axios";
import BonneApartApis from "../Utils/bonneApartApis";
import { toast } from "react-hot-toast";

export default function FormContext() {
  const methods = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("project_price", data.project_price);
      formData.append("project_name", data.project_name);
      formData.append(
        "project_short_description",
        data.project_short_description
      );
      formData.append("project_keywords", data.project_keywords);
      formData.append("project_type", data.project_type);
      formData.append("developer_name", data.developer_name);
      formData.append("developer_logo", data.developer_logo[0]);
      formData.append("background_image", data.background_image[0]);

      await Instance.post(BonneApartApis.SaveProject, formData).then(
        (response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            navigate("/edit-landing-page/" + response.data.id);
          }
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <GeneralForm />
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <DeveloperFileUploader requiredfield={true} />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <BackgroundImageFileUploader />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button type="submit" variant="contained" color="primary">
                    Save & Continue
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
