import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import FormLoader from "shared/components/Loader/FormLoader";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import InnerPageComponent from "shared/components/Utils/InnerPageComponent";
import AmenityInputFields from "./AmenityInputFields";

export default function SaveAmenity() {
  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(BonneApartApis.SaveAmenity, data);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        methods.reset();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <InnerPageComponent title="New Amenity" />
      <Box mt={10}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              maxWidth="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                width="50%"
                sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <Grid container spacing={2}>
                  <AmenityInputFields />
                  <Grid item xs={12} md={12} lg={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={methods.formState.isSubmitting}
                      >
                        {methods.formState.isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <>Save Amenity</>
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}
