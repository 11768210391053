import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BNAofferandpromotionsInnerfieldsforGerman from "./BNAofferandpromotionsInnerfieldsforGerman";
import { Box, Button, Grid } from "@mui/material";
import FormLoader from "shared/components/Loader/FormLoader";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Instance from "shared/components/Utils/axios";
import BonneApartApis from "shared/components/Utils/bonneApartApis";
import { useNavigate } from "react-router-dom";

export default function EditBNAofferandpromotionsFormForGerman() {
  const [offerandpromotions, setOfferandpromotions] = useState({});
  const navigate = useNavigate();
  const { id: offerpropmotionID } = useParams();
  const methods = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await Instance.post(
        BonneApartApis.UpdateOfferandPromotionsDe + "/" + offerpropmotionID,
        data
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const CallForBNAofferandpromotionsDe = async () => {
      try {
        const response = await Instance.get(
          BonneApartApis.GetSingleOfferandPromotionsDe + "/" + offerpropmotionID
        );

        if (response.data.status === "success") {
          setOfferandpromotions(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    CallForBNAofferandpromotionsDe();
  }, []);

  return (
    <>
      <Box
        margin="20px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="50%"
          sx={{
            "@media (max-width: 600px)": {
              width: "100%",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <BNAofferandpromotionsInnerfieldsforGerman
                data={offerandpromotions}
              />
              <Grid container justifyContent="flex-end" mt={2}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <FormLoader />
                    ) : (
                      "Update Offer & Promotions"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </>
  );
}
